import {
  DEALS_LIST,
  MARKETPLACE_LIST,
  META_PRODUCTS_LIST,
  META_SPECIALITIES_LIST,
  SERVICES_LIST,
} from '../static';

function hasOnlyDigitChar(str) {
  return /^\d+$/.test(str);
}

function isEmptyValue(value) {
  return (
    value === undefined ||
    value === null ||
    value === NaN ||
    (typeof value === 'object' &&
      Object.values(value).every(
        (o) => o === null || o === 'undefined' || o === ''
      )) ||
    (typeof value === 'string' && value.trim().length === 0)
  );
}

function fetchTextSearchOptions(
  servicesList = SERVICES_LIST,
  specialitiesList = META_SPECIALITIES_LIST,
  dealsList = DEALS_LIST,
  productsList = META_PRODUCTS_LIST,
  marketplaceList = MARKETPLACE_LIST,
  language = 'english'
) {
  let searchTextOptions = [];
  const filteredServiceList = servicesList.filter(
    (record) => !isEmptyValue(record.searchQuery)
  );
  const filteredSpecialitiesList = specialitiesList.filter(
    (record) => !isEmptyValue(record.searchQuery)
  );
  const filteredDealsList = dealsList.filter(
    (record) => !isEmptyValue(record.searchQuery)
  );
  const filteredProductsList = productsList.filter(
    (record) => !isEmptyValue(record.searchQuery)
  );
  const filteredMarketplaceList = marketplaceList.filter(
    (record) => !isEmptyValue(record.searchQuery)
  );

  searchTextOptions = [
    ...searchTextOptions,
    ...filteredMarketplaceList.map((record) => {
      return {
        value: record.key,
        label: record.searchQuery,
        type: 'marketplace',
        category: record.category,
      };
    }),
    ...filteredProductsList.map((record) => {
      let categoryGroup = '';
      if (dealsList.some((obj) => obj.key === record.area)) {
        categoryGroup = 'deals-discounts';
      } else if (marketplaceList.some((obj) => obj.key === record.area)) {
        categoryGroup = 'buy-sell-rent';
      }
      return {
        value: record.key,
        label: record.searchQuery,
        type: 'product',
        area: record.area,
        categoryGroup: categoryGroup,
      };
    }),
    ...filteredServiceList.map((record) => {
      return {
        value: record.key,
        label: record.searchQuery,
        type: 'services',
        service: record.key,
        category: record.category,
      };
    }),
    ...filteredSpecialitiesList.map((record) => {
      return {
        value: record.key,
        label: record.searchQuery,
        type: 'speciality',
        service: record.service,
        area: record.service,
      };
    }),
    ...filteredDealsList.map((record) => {
      return {
        value: record.key,
        label: record.searchQuery,
        type: 'deals-discounts',
        category: record.category,
      };
    }),
  ];
  return searchTextOptions;
}

export { hasOnlyDigitChar, fetchTextSearchOptions, isEmptyValue };
