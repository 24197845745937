export const META_PRODUCTS_LIST = [
  {
    key: 'beauty-items-makeup',
    name: 'Makeup',
  },
  {
    key: 'women-clothing-pants-jeans',
    name: 'Pants & Jeans',
  },
  {
    key: 'women-clothing-shirts-tops',
    name: 'Shirts & Tops',
  },
  {
    key: 'women-clothing-jackets-outerwear',
    name: 'Jackets & Outerwear',
  },
  {
    key: 'women-clothing-lounge-activewear',
    name: 'Lounge & Activewear',
  },
  {
    key: 'women-clothing-dresses-skirts',
    name: 'Dresses & Skirts',
  },
  {
    key: 'mens-clothing-jackets-outerwear',
    name: 'Jackets & Outerwear',
  },
  {
    key: 'mens-clothing-lounge-activewear',
    name: 'Lounge & Activewear',
  },
  {
    key: 'mens-clothing-pants-jeans',
    name: 'Pants & Jeans',
  },
  {
    key: 'beauty-items-nail-care',
    name: 'Nail Care',
  },
  {
    key: 'beauty-items-hair-care',
    name: 'Hair Care',
  },
  {
    key: 'beauty-items-sun-protection',
    name: 'Sun Protection',
  },
  {
    key: 'beauty-items-skin-care',
    name: 'Skin Care',
  },
  {
    key: 'beauty-items-dental-care',
    name: 'Dental Care',
  },
  {
    key: 'beauty-items-beauty-tools-accessories',
    name: 'Beauty Tools & Accessories',
  },
  {
    key: 'beauty-items-bath-body',
    name: 'Bath & Body',
  },
  {
    key: 'beauty-items-foot-care',
    name: 'Foot Care',
  },
  {
    key: 'beauty-items-vitamins-supplements',
    name: 'Vitamins & Supplements',
  },
  {
    key: 'beauty-items-fragrances',
    name: 'Fragrances',
  },
  {
    key: 'buy-sell-computer-mobile-electronics-mobile',
    name: 'Mobile',
  },
  {
    key: 'buy-sell-computer-mobile-electronics-laptop',
    name: 'Laptop',
  },
  {
    key: 'buy-sell-computer-mobile-electronics-tablet',
    name: 'Tablet',
  },
  {
    key: 'buy-sell-computer-mobile-electronics-desktop',
    name: 'Desktop',
  },
  {
    key: 'beauty-items-anti-aging',
    name: 'Anti aging',
  },
  {
    key: 'mens-clothing-shorts-for-men',
    name: 'Shorts for Men',
  },
  {
    key: 'mens-clothing-socks-underwear',
    name: 'Socks & Underwear',
  },
  {
    key: 'mens-clothing-sweaters-sweatshirts',
    name: 'Sweaters & Sweatshirts',
  },
  {
    key: 'mens-clothing-swim-dress',
    name: 'Swim dress',
  },
  {
    key: 'mens-clothing-sleepwear',
    name: 'Sleepwear',
  },
  {
    key: 'mens-clothing-winter-wear',
    name: 'Winter wear',
  },
  {
    key: 'mens-clothing-undergarments',
    name: 'Undergarments',
  },
  {
    key: 'jewellery-bracelets',
    name: 'Bracelets',
  },
  {
    key: 'jewellery-payal-and-bichhiya',
    name: 'Payal and bichhiya',
  },
  {
    key: 'jewellery-bridal-wedding-ring-sets',
    name: 'Bridal & Wedding Ring Sets',
  },
  {
    key: 'jewellery-earrings',
    name: 'Earrings',
  },
  {
    key: 'jewellery-kamar-bandh',
    name: 'Kamar bandh',
  },
  {
    key: 'jewellery-nose-rings',
    name: 'Nose rings',
  },
  {
    key: 'jewellery-necklaces',
    name: 'Necklaces',
  },
  {
    key: 'jewellery-watches',
    name: 'Watches',
  },
  {
    key: 'jewellery-jewelry-sets',
    name: 'Jewelry Sets',
  },
  {
    key: 'jewellery-rings',
    name: 'Rings',
  },
  {
    key: 'kids-clothing-activewear',
    name: 'Activewear',
  },
  {
    key: 'kids-clothing-clothing-sets',
    name: 'Clothing sets',
  },
  {
    key: 'kids-clothing-pants',
    name: 'Pants',
  },
  {
    key: 'kids-clothing-outerwear',
    name: 'Outerwear',
  },
  {
    key: 'kids-clothing-shirts',
    name: 'Shirts',
  },
  {
    key: 'kids-clothing-shorts',
    name: 'Shorts',
  },
  {
    key: 'kids-clothing-sleepwear',
    name: 'Sleepwear',
  },
  {
    key: 'kids-clothing-swim-dress',
    name: 'Swim dress',
  },
  {
    key: 'kids-clothing-school-uniform',
    name: 'School uniform',
  },
  {
    key: 'kids-clothing-undergarments',
    name: 'Undergarments',
  },
  {
    key: 'kids-clothing-dresses-for-girls',
    name: 'Dresses for Girls',
  },
  {
    key: 'women-clothing-shorts',
    name: 'Shorts',
  },
  {
    key: 'women-clothing-sleepwear',
    name: 'Sleepwear',
  },
  {
    key: 'women-clothing-socks-underwear',
    name: 'Socks & Underwear',
  },
  {
    key: 'women-clothing-sweaters-cardigans',
    name: 'Sweaters & Cardigans',
  },
  {
    key: 'women-clothing-sari',
    name: 'Sari',
  },
  {
    key: 'women-clothing-salwar-kurta',
    name: 'Salwar Kurta',
  },
  {
    key: 'women-clothing-ghagra-choli',
    name: 'Ghagra Choli',
  },
  {
    key: 'women-clothing-dress-sets',
    name: 'Dress sets',
  },
  {
    key: 'women-clothing-swim',
    name: 'Swim',
  },
  {
    key: 'women-clothing-fan-gear',
    name: 'Fan Gear',
  },
  {
    key: 'women-clothing-winter-wear',
    name: 'Winter wear',
  },
  {
    key: 'women-clothing-inner-wear',
    name: 'Inner wear',
  },
  {
    key: 'women-clothing-other-traditional-costume',
    name: 'Other traditional costume',
  },
  {
    key: 'footwear-shoes-boots',
    name: 'Boots',
  },
  {
    key: 'footwear-shoes-casual-shoes',
    name: 'Casual Shoes',
  },
  {
    key: 'footwear-shoes-dress-shoes',
    name: 'Dress Shoes',
  },
  {
    key: 'footwear-shoes-ladies-footwear',
    name: 'Ladies footwear',
  },
  {
    key: 'footwear-shoes-gents-footwear',
    name: 'Gents footwear',
  },
  {
    key: 'footwear-shoes-kids-footwear',
    name: 'Kids Footwear',
  },
  {
    key: 'footwear-shoes-athletic-shoes-sneakers',
    name: 'Athletic Shoes & Sneakers',
  },
  {
    key: 'footwear-shoes-wedding-footwear',
    name: 'Wedding footwear',
  },
  {
    key: 'footwear-shoes-flats',
    name: 'Flats',
  },
  {
    key: 'footwear-shoes-heels-footwear',
    name: 'Heels footwear',
  },
  {
    key: 'footwear-shoes-sandals',
    name: 'Sandals',
  },
  {
    key: 'footwear-shoes-flip-flops',
    name: 'Flip-Flops',
  },
  {
    key: 'footwear-shoes-shoe-care-accessories',
    name: 'Shoe Care & Accessories',
  },
  {
    key: 'perfumes-deals-perfumes-gifts-sets',
    name: 'Perfumes Gifts & Sets',
  },
  {
    key: 'perfumes-deals-fragrance',
    name: 'Fragrance',
  },
  {
    key: 'perfumes-deals-candles-diffusers',
    name: 'Candles & Diffusers',
  },
  {
    key: 'perfumes-deals-perfumes-rollerball-travel-size',
    name: 'Perfumes Rollerball & Travel Size',
  },
  {
    key: 'sunglasses-deals-designer-glasses',
    name: 'Designer Glasses',
  },
  {
    key: 'sunglasses-deals-rayban',
    name: 'RayBan',
  },
  {
    key: 'sunglasses-deals-polarized',
    name: 'Polarized',
  },
  {
    key: 'sunglasses-deals-prescription-glasses',
    name: 'Prescription Glasses',
  },
  {
    key: 'sunglasses-deals-contact-lenses',
    name: 'Contact Lenses',
  },
  {
    key: 'sunglasses-deals-rx-sunglasses',
    name: 'Rx Sunglasses',
  },
  {
    key: 'sunglasses-deals-multifocal-glasses',
    name: 'Multifocal Glasses',
  },
  {
    key: 'sunglasses-deals-kids-glasses',
    name: 'Kids Glasses',
  },
  {
    key: 'home-office-furniture-deals-bedroom-furniture',
    name: 'Bedroom Furniture',
  },
  {
    key: 'home-office-furniture-deals-dressers-chest',
    name: 'Dressers & Chest',
  },
  {
    key: 'home-office-furniture-deals-kids-furniture',
    name: 'Kids Furniture',
  },
  {
    key: 'home-office-furniture-deals-kitchen-dining-room-furniture',
    name: 'Kitchen & Dining Room Furniture',
  },
  {
    key: 'home-office-furniture-deals-bar-wine-cabinets',
    name: 'Bar & Wine Cabinets',
  },
  {
    key: 'home-office-furniture-deals-living-room-furniture',
    name: 'Living Room Furniture',
  },
  {
    key: 'home-office-furniture-deals-nursery-baby-furniture',
    name: 'Nursery & Baby Furniture',
  },
  {
    key: 'home-office-furniture-deals-office-home-office-furniture',
    name: 'Office & Home Office Furniture',
  },
  {
    key: 'buy-sell-home-office-furniture-bed',
    name: 'Bed',
  },
  {
    key: 'buy-sell-home-office-furniture-sofa',
    name: 'Sofa',
  },
  {
    key: 'home-office-furniture-deals-bookcases-bookshelves',
    name: 'Bookcases & Bookshelves',
  },
  {
    key: 'home-office-furniture-deals-tv-stands-entertainment-centers',
    name: 'TV Stands & Entertainment Centers',
  },
  {
    key: 'home-office-furniture-deals-bar-stools-counter-stools',
    name: 'Bar Stools & Counter Stools',
  },
  {
    key: 'home-decoration-art-wall-decoration',
    name: 'Art & Wall decoration',
  },
  {
    key: 'home-decoration-artificial-plants',
    name: 'Artificial Plants',
  },
  {
    key: 'home-decoration-curtains-drapes',
    name: 'Curtains & Drapes',
  },
  {
    key: 'home-decoration-home-accents',
    name: 'Home Accents',
  },
  {
    key: 'home-decoration-lighting',
    name: 'Lighting',
  },
  {
    key: 'home-decoration-rugs',
    name: 'Rugs',
  },
  {
    key: 'home-decoration-home-fragrances',
    name: 'Home fragrances',
  },
  {
    key: 'home-decoration-mirrors',
    name: 'Mirrors',
  },
  {
    key: 'home-decoration-wind-chimes',
    name: 'Wind chimes',
  },
  {
    key: 'home-decoration-seasonal-decorations',
    name: 'Seasonal decorations',
  },
  {
    key: 'home-decoration-furniture-decorations',
    name: 'Furniture decorations',
  },
  {
    key: 'computer-deals-webcams',
    name: 'Webcams',
  },
  {
    key: 'home-appliances-cooking-appliances',
    name: 'Cooking Appliances',
  },
  {
    key: 'home-appliances-dishwashers',
    name: 'Dishwashers',
  },
  {
    key: 'home-appliances-air-conditioners',
    name: 'Air Conditioners',
  },
  {
    key: 'home-appliances-microwaves',
    name: 'Microwaves',
  },
  {
    key: 'home-appliances-refrigerators',
    name: 'Refrigerators',
  },
  {
    key: 'home-appliances-air-fryers',
    name: 'Air Fryers',
  },
  {
    key: 'home-appliances-freezers-ice-makers',
    name: 'Freezers & Ice Makers',
  },
  {
    key: 'home-appliances-air-purifiers-filters',
    name: 'Air Purifiers & Filters',
  },
  {
    key: 'home-appliances-blenders-juicers',
    name: 'Blenders & Juicers',
  },
  {
    key: 'home-appliances-coffee-makers-accessories',
    name: 'Coffee Makers & Accessories',
  },
  {
    key: 'home-appliances-mixers-food-processors',
    name: 'Mixers & Food Processors',
  },
  {
    key: 'home-appliances-water-coolers-dispensers',
    name: 'Water Coolers & Dispensers',
  },
  {
    key: 'home-appliances-vacuums-floor-care',
    name: 'Vacuums & Floor Care',
  },
  {
    key: 'home-appliances-washers-dryers',
    name: 'Washers & Dryers',
  },
  {
    key: 'home-appliances-fans',
    name: 'Fans',
  },
  {
    key: 'computer-deals-desktop-computers-pcs',
    name: 'Desktop Computers & PCs',
  },
  {
    key: 'computer-deals-laptops-notebook-computers',
    name: 'Laptops & Notebook Computers',
  },
  {
    key: 'computer-deals-mac',
    name: 'Mac',
  },
  {
    key: 'computer-deals-chromebooks',
    name: 'Chromebooks',
  },
  {
    key: 'computer-deals-tablets',
    name: 'Tablets',
  },
  {
    key: 'computer-deals-printers',
    name: 'Printers',
  },
  {
    key: 'computer-deals-keyboards-mouse',
    name: 'Keyboards & Mouse',
  },
  {
    key: 'computer-deals-speakers',
    name: 'Speakers',
  },
  {
    key: 'computer-deals-software',
    name: 'Software',
  },
  {
    key: 'computer-deals-printer-ink-toner-cartridges',
    name: 'Printer Ink & Toner Cartridges',
  },
  {
    key: 'computer-deals-routers-wifi-extenders-modems',
    name: 'Routers, Wifi Extenders & Modems',
  },
  {
    key: 'electronics-deals-tvs',
    name: 'TVs',
  },
  {
    key: 'electronics-deals-tv-mounts-installations-accessories',
    name: 'TV Mounts, Installations & Accessories',
  },
  {
    key: 'electronics-deals-cameras-camcorders',
    name: 'Cameras & Camcorders',
  },
  {
    key: 'electronics-deals-soundbars-home-theater',
    name: 'Soundbars & Home Theater',
  },
  {
    key: 'electronics-deals-streaming-devices',
    name: 'Streaming Devices',
  },
  {
    key: 'electronics-deals-batteries',
    name: 'Batteries',
  },
  {
    key: 'electronics-deals-digital-picture-frames',
    name: 'Digital Picture Frames',
  },
  {
    key: 'electronics-deals-drones',
    name: 'Drones',
  },
  {
    key: 'electronics-deals-musical-instruments',
    name: 'Musical Instruments',
  },
  {
    key: 'electronics-deals-cordless-telephones',
    name: 'Cordless Telephones',
  },
  {
    key: 'electronics-deals-security-cameras-video-surveillance',
    name: 'Security Cameras & Video Surveillance',
  },
  {
    key: 'electronics-deals-smart-lighting',
    name: 'Smart Lighting',
  },
  {
    key: 'electronics-deals-video-games-consoles',
    name: 'Video Games & Consoles',
  },
  {
    key: 'electronics-deals-camera-accessories',
    name: 'Camera Accessories',
  },
  {
    key: 'mobile-phone-deals-cell-phones',
    name: 'Cell Phones',
  },
  {
    key: 'mobile-phone-deals-headphones-earbuds',
    name: 'Headphones & Earbuds',
  },
  {
    key: 'mobile-phone-deals-cell-phones-accessories',
    name: 'Cell Phones Accessories',
  },
  {
    key: 'mobile-phone-deals-apple-watch',
    name: 'Apple Watch',
  },
  {
    key: 'mobile-phone-deals-smart-watches',
    name: 'Smart Watches',
  },
  {
    key: 'home-office-furniture-deals-sofas-couches-loveseats',
    name: 'Sofas, Couches & Loveseats',
  },
  {
    key: 'buy-sell-home-office-furniture-dresser',
    name: 'Dresser',
  },
  {
    key: 'buy-sell-home-office-furniture-chest',
    name: 'Chest',
  },
  {
    key: 'buy-sell-home-office-furniture-dining-table',
    name: 'Dining Table',
  },
  {
    key: 'buy-sell-home-office-furniture-chair',
    name: 'Chair',
  },
  {
    key: 'buy-sell-home-office-furniture-kitchen-furniture',
    name: 'Kitchen furniture',
  },
  {
    key: 'buy-sell-home-office-furniture-office-furniture',
    name: 'Office furniture',
  },
  {
    key: 'buy-sell-home-office-furniture-study-table',
    name: 'Study Table',
  },
  {
    key: 'buy-sell-appliances-air-conditioner',
    name: 'Air Conditioner',
  },
  {
    key: 'buy-sell-appliances-fridge',
    name: 'Fridge',
  },
  {
    key: 'buy-sell-appliances-washing-machine',
    name: 'Washing machine',
  },
  {
    key: 'buy-sell-appliances-microwave',
    name: 'Microwave',
  },
  {
    key: 'mens-clothing-footwear-boots',
    name: 'Footwear & Boots',
  },
  {
    key: 'household-items-utensils',
    name: 'Utensils',
  },
  {
    key: 'household-items-steel-buckets',
    name: 'Steel buckets',
  },
  {
    key: 'buy-sell-musical-instrument-electric-guitar',
    name: 'Electric Guitar',
  },
  {
    key: 'buy-sell-musical-instrument-non-electric-guitar',
    name: 'Non-electric Guitar',
  },
  {
    key: 'buy-sell-musical-instrument-harmonium',
    name: 'Harmonium',
  },
  {
    key: 'buy-sell-musical-instrument-tabla',
    name: 'Tabla',
  },
  {
    key: 'buy-sell-musical-instrument-sitar',
    name: 'Sitar',
  },
  {
    key: 'buy-sell-musical-instrument-flute',
    name: 'Flute',
  },
  {
    key: 'mens-clothing-shirts-for-men',
    name: 'Shirts for Men',
  },
  {
    key: 'buy-sell-musical-instrument-veena',
    name: 'Veena',
  },
  {
    key: 'buy-sell-musical-instrument-violin',
    name: 'Violin',
  },
  {
    key: 'buy-sell-musical-instrument-piano',
    name: 'Piano',
  },
  {
    key: 'women-clothing-lingerie',
    name: 'Lingerie',
  },
  {
    key: 'sunglasses-deals-lightweight-glasses',
    name: 'Lightweight Glasses',
  },
  {
    key: 'sunglasses-deals-sports-glasses',
    name: 'Sports Glasses',
  },
  {
    key: 'computer-deals-storage-devices',
    name: 'Storage devices',
  },
];
export const META_SPECIALITIES_LIST = [
  {
    key: 'music-flute',
    name: 'Flute',
  },
  {
    key: 'music-tabla',
    name: 'Tabla',
  },
  {
    key: 'ec-bank',
    name: 'Bank Exam',
  },
  {
    key: 'ec-ielts',
    name: 'IELTS',
  },
  {
    key: 'music-hindustani-vocal',
    name: 'Hindustani Classical Vocal',
  },
  {
    key: 'st-elementary',
    name: 'Elementary (Class 1 - 5 Tuition)',
  },
  {
    key: 'sc-chemistry',
    name: 'Chemistry',
  },
  {
    key: 'sc-physics',
    name: 'Physics',
  },
  {
    key: 'sc-science',
    name: 'Science',
  },
  {
    key: 'ec-psc',
    name: 'PSC/ UPSC',
  },
  {
    key: 'ec-ssc',
    name: 'SSC Exam',
  },
  {
    key: 'sc-english',
    name: 'English',
  },
  {
    key: 'st-nursery-kg',
    name: 'Nursery and KG Tuition',
  },
  {
    key: 'mc-camera-repair',
    name: 'Camera Repair',
  },
  {
    key: 'ec-engineering',
    name: 'Engineering Entrance/ IIT-JEE',
  },
  {
    key: 'art-oil-painting',
    name: 'Oil painting',
  },
  {
    key: 'mobile-computer-repair_cctv_installation_repair',
    name: 'CCTV Installation/ Repair',
  },
  {
    key: 'da-bharatanatyam',
    name: 'Bharatanatyam',
  },
  {
    key: 'da-kathakali',
    name: 'Kathakali',
  },
  {
    key: 'da-kathak',
    name: 'Kathak',
  },
  {
    key: 'da-kuchipudi',
    name: 'Kuchipudi',
  },
  {
    key: 'da-odissi',
    name: 'Odissi',
  },
  {
    key: 'da-manipuri',
    name: 'Manipuri',
  },
  {
    key: 'da-ballet',
    name: 'Ballet',
  },
  {
    key: 'da-ballroom',
    name: 'Ballroom',
  },
  {
    key: 'da-contemporary',
    name: 'Contemporary',
  },
  {
    key: 'da-folk-dance',
    name: 'Folk Dance',
  },
  {
    key: 'pd-x-ray',
    name: 'X-Ray',
  },
  {
    key: 'pd-blood-test',
    name: 'Blood Related Test',
  },
  {
    key: 'pd-sonography',
    name: 'Sonography',
  },
  {
    key: 'pd-ct-scan',
    name: 'CT Scan',
  },
  {
    key: 'pd-mri-pet',
    name: 'MRI/PET Scan',
  },
  {
    key: 'pd-stool-test',
    name: 'Stool Related Test',
  },
  {
    key: 'pd-urine-test',
    name: 'Urine Related Test',
  },
  {
    key: 'do-family-physician',
    name: 'Family Physician',
  },
  {
    key: 'do-internal-medicine',
    name: 'Internal Medicine ',
  },
  {
    key: 'do-pediatrician',
    name: 'Pediatrician',
  },
  {
    key: 'do-obs-gyn',
    name: 'Obstetrician/gynecologist (Ob/gyn)',
  },
  {
    key: 'do-cardiologist',
    name: 'Cardiologist',
  },
  {
    key: 'do-dermatologist',
    name: 'Dermatologist',
  },
  {
    key: 'do-endocrinologist',
    name: 'Endocrinologist',
  },
  {
    key: 'do-pulmonologist',
    name: 'Pulmonologist ',
  },
  {
    key: 'do-gastroenterologist',
    name: 'Gastroenterologist',
  },
  {
    key: 'do-neurologist',
    name: 'Neurologist',
  },
  {
    key: 'do-radiologist',
    name: 'Radiologist',
  },
  {
    key: 'do-neurosurgeon',
    name: 'Neurosurgeon',
  },
  {
    key: 'do-oncologist',
    name: 'Oncologist',
  },
  {
    key: 'do-ent-specialist',
    name: 'ENT Specialist',
  },
  {
    key: 'ma-refrigerator-repair',
    name: 'Refrigerator Repair',
  },
  {
    key: 'language-classes-arabic',
    name: 'Arabic',
  },
  {
    key: 'language-classes-bengali',
    name: 'Bengali',
  },
  {
    key: 'language-classes-french',
    name: 'French',
  },
  {
    key: 'language-classes-german',
    name: 'German',
  },
  {
    key: 'language-classes-gujrati',
    name: 'Gujrati',
  },
  {
    key: 'language-classes-hindi',
    name: 'Hindi',
  },
  {
    key: 'language-classes-japanese',
    name: 'Japanese',
  },
  {
    key: 'language-classes-kannada',
    name: 'Kannada',
  },
  {
    key: 'language-classes-portuguese',
    name: 'Portuguese',
  },
  {
    key: 'language-classes-spanish',
    name: 'Spanish',
  },
  {
    key: 'language-classes-tamil',
    name: 'Tamil',
  },
  {
    key: 'language-classes-urdu',
    name: 'Urdu',
  },
  {
    key: 'music-guitar',
    name: 'Guitar',
  },
  {
    key: 'pest-control-snake-catcher',
    name: 'Snake catcher',
  },
  {
    key: 'ec-ca',
    name: 'CA/ CS Exam',
  },
  {
    key: 'ct-mba',
    name: 'CAT/ MBA Entrance',
  },
  {
    key: 'psychiatry-services-family-marriage-counselor',
    name: 'Family/ Marriage counselor',
  },
  {
    key: 'psychiatry-services-psychologist',
    name: 'Psychologist',
  },
  {
    key: 'psychiatry-services-psychotherapist',
    name: 'Psychotherapist',
  },
  {
    key: 'ec-gre-gmat',
    name: 'GRE/ GMAT/ TOEFL',
  },
  {
    key: 'ec-opthalmologist',
    name: 'Ophthalmologist',
  },
  {
    key: 'ps-insurance-services-automobile-insurance',
    name: 'Automobile Insurance',
  },
  {
    key: 'ps-insurance-services-life-insurance',
    name: 'Life Insurance',
  },
  {
    key: 'ps-insurance-services-travel-insurance',
    name: 'Travel Insurance',
  },
  {
    key: 'ps-insurance-services-property-insurance',
    name: 'Property Insurance',
  },
  {
    key: 'ps-insurance-services-health-insurance',
    name: 'Health Insurance',
  },
  {
    key: 'ps-insurance-services-house-insurance',
    name: 'House insurance',
  },
  {
    key: 'ps-real-estate-agent-management-full-service-brokerage',
    name: 'Full-Service Brokerage',
  },
  {
    key: 'ds-dermatology',
    name: 'Dermatalogy',
  },
  {
    key: 'hair-skin-care-services-hair-transplant',
    name: 'Hair Transplant',
  },
  {
    key: 'hair-skin-care-services-botox-treatment',
    name: 'Botox Treatment',
  },
  {
    key: 'hair-skin-care-services-cosmetic-surgery',
    name: 'Cosmetic Surgery',
  },
  {
    key: 'alternative-therapy-medicine-acupressure-therapy',
    name: 'Acupressure Therapy',
  },
  {
    key: 'alternative-therapy-medicine-acupuncture-therapy',
    name: 'Acupuncture Therapy',
  },
  {
    key: 'alternative-therapy-medicine-ayurvedic',
    name: 'Ayurvedic',
  },
  {
    key: 'alternative-therapy-medicine-homeopathic',
    name: 'Homeopathic',
  },
  {
    key: 'alternative-therapy-medicine-reiki-therapy',
    name: 'Reiki Therapy',
  },
  {
    key: 'veterinary-services-veterinary-doctors',
    name: 'Veterinary Doctors',
  },
  {
    key: 'other-medical-services-nutritionist',
    name: 'Nutritionist',
  },
  {
    key: 'other-medical-services-dietitian',
    name: 'Dietitian',
  },
  {
    key: 'other-medical-services-laser-treatments',
    name: 'Laser Treatments',
  },
  {
    key: 'other-medical-services-liposuctions',
    name: 'Liposuctions',
  },
  {
    key: 'art-drawing-sketching',
    name: 'Drawing/ Sketching',
  },
  {
    key: 'hobby-decorative-art-regional-art-classes',
    name: 'Regional Art Classes',
  },
  {
    key: 'event-catering_mehndi_artist',
    name: 'Mehndi Artist',
  },
  {
    key: 'language-classes-malyalam',
    name: 'Malyalam',
  },
  {
    key: 'hobby-music-singing-drum',
    name: 'Drum',
  },
  {
    key: 'hobby-music-singing-harmonium',
    name: 'Harmonium',
  },
  {
    key: 'hobby-music-singing-semi-classical-vocal-music',
    name: 'Semi Classical Vocal Music',
  },
  {
    key: 'hobby-music-singing-movie-songs',
    name: 'Movie Songs',
  },
  {
    key: 'hobby-music-singing-piano-keyboard',
    name: 'Piano/ Keyboard',
  },
  {
    key: 'hobby-music-singing-rabindra-sangeet',
    name: 'Rabindra Sangeet',
  },
  {
    key: 'hobby-music-singing-sarangi',
    name: 'Sarangi',
  },
  {
    key: 'hobby-music-singing-sitar',
    name: 'Sitar',
  },
  {
    key: 'hobby-music-singing-tambura',
    name: 'Tambura',
  },
  {
    key: 'hobby-music-singing-violin',
    name: 'Violin',
  },
  {
    key: 'hobby-music-singing-galzal-singing',
    name: 'Galzal Singing',
  },
  {
    key: 'event-catering-wedding-catering',
    name: 'Wedding Catering',
  },
  {
    key: 'event-catering-corporate-catering',
    name: 'Corporate Catering',
  },
  {
    key: 'school-tuition-classes-middle-class-(6th--8th)-tuition',
    name: 'Middle Class (6th -8th) Tuition',
  },
  {
    key: 'school-tuition-classes-primary-class-tuition',
    name: 'Primary Class Tuition',
  },
  {
    key: 'school-tuition-classes-commerce',
    name: 'Commerce',
  },
  {
    key: 'school-tuition-classes-sanskrit',
    name: 'Sanskrit',
  },
  {
    key: 'exam-coachings-defence/-ssb-entrance',
    name: 'Defence/ SSB Entrance',
  },
  {
    key: 'hr-career-advance-communication-skills',
    name: 'Communication skills',
  },
  {
    key: 'hr-career-advance-interview-preparation',
    name: 'Interview Preparation',
  },
  {
    key: 'hr-career-advance-personality-development',
    name: 'Personality Development',
  },
  {
    key: 'hobby-music-singing-sarod',
    name: 'Sarod',
  },
  {
    key: 'hobby-music-singing-shehnai',
    name: 'Shehnai',
  },
  {
    key: 'hr-career-advance-leadership-skills',
    name: 'Leadership Skills',
  },
  {
    key: 'hr-career-advance-resume-writer',
    name: 'Resume writer',
  },
  {
    key: 'hr-career-advance-soft-skills-development',
    name: 'Soft Skills Development',
  },
  {
    key: 'school-tuition-classes-class-11th-12th-tuition',
    name: 'Class 11th/ 12th Tuition',
  },
  {
    key: 'other-domestic-services-bill-payment-services',
    name: 'Bill Payment Services',
  },
  {
    key: 'other-domestic-services-water-treatment-purification',
    name: 'Water Treatment & Purification',
  },
  {
    key: 'cook-catering-tiffin-service',
    name: 'Tiffin service',
  },
  {
    key: 'domestic-delivery-mineral-water',
    name: 'Mineral water',
  },
  {
    key: 'music-piano',
    name: 'Piano',
  },
  {
    key: 'language-classes-british/american-english',
    name: 'British/american English',
  },
  {
    key: 'at-tax-preparation',
    name: 'Tax Preparation',
  },
  {
    key: 'fortune-telling-astrology-vaastu-consultants',
    name: 'Vaastu Consultants',
  },
  {
    key: 'fortune-telling-astrology-horoscope-creation',
    name: 'Horoscope creation',
  },
  {
    key: 'domestic-delivery-water-tanker',
    name: 'Water Tanker',
  },
  {
    key: 'ec-medical',
    name: 'Medical Entrance/ NEET',
  },
  {
    key: 'sc-maths',
    name: 'Mathematics',
  },
  {
    key: 'school-tuition-classes-class-9th-10th-tuition',
    name: 'Class 9th/ 10th Tuition',
  },
  {
    key: 'other-party-entertainment-photo-booth-/-photographer',
    name: 'Photo Booth / Photographer',
  },
  {
    key: 'hobby-music-singing_western-vocals',
    name: 'Western-Vocals',
  },
  {
    key: 'hobby-dance_hip_hop',
    name: 'Hip Hop',
  },
  {
    key: 'hobby-dance_free_style',
    name: 'Free Style',
  },
  {
    key: 'hobby-dance_bhangra',
    name: 'Bhangra',
  },
  {
    key: 'hobby-dance_aerobics_yoga_fitness_classes',
    name: 'Aerobics/ Yoga/ Fitness Classes',
  },
  {
    key: 'travel-service_flight_tickets',
    name: 'Flight Tickets',
  },
  {
    key: 'hobby-dance_jazz',
    name: 'Jazz',
  },
  {
    key: 'other_service_not_listed_fine_arts',
    name: 'Fine Arts',
  },
  {
    key: 'travel-service_embassy_services',
    name: 'Embassy Services',
  },
  {
    key: 'travel-service_apostille_services_mea_attestation',
    name: 'Apostille Services /MEA Attestation',
  },
  {
    key: 'travel-service_wild_life_tour',
    name: 'Wild Life Tour',
  },
  {
    key: 'travel-service_pilgrimage_tour',
    name: 'Pilgrimage Tour',
  },
  {
    key: 'travel-service_international_tour',
    name: 'International Tour',
  },
  {
    key: 'travel-service_honeymoon_packages',
    name: 'Honeymoon Packages',
  },
  {
    key: 'travel-service_cruise_tours',
    name: 'Cruise Tours',
  },
  {
    key: 'travel-service_road_tours',
    name: 'Road Tours',
  },
  {
    key: 'school-tuition-classes_economics',
    name: 'Economics',
  },
  {
    key: 'exam-coachings_cuet_(ug)',
    name: 'CUET (UG)',
  },
  {
    key: 'school-tuition-classes_graduationmasters_tuitions',
    name: 'Graduation/Masters  Tuitions',
  },
  {
    key: 'school-tuition-classes_social_studies',
    name: 'Social Studies',
  },
  {
    key: 'school-tuition-classes_biology',
    name: 'Biology',
  },
  {
    key: 'school-tuition-classes_accounting',
    name: 'Accounting',
  },
  {
    key: 'ma-air-installation',
    name: 'Air Condition Installation & Repair',
  },
  {
    key: 'fortune-telling-astrology-astrologer',
    name: 'Astrologer',
  },
  {
    key: 'fortune-telling-astrology-numerologist',
    name: 'Numerologist',
  },
  {
    key: 'fortune-telling-astrology-palmist',
    name: 'Palmist',
  },
];

export const META_MARKETPLACE_LIST = [
  {
    key: 'buy-sell-house-bungalow-villa',
    name: 'House/ Bungalow/ Villa',
  },
  {
    key: 'buy-sell-apartment-flat',
    name: 'Apartment/ Flat',
  },
  {
    key: 'buy-sell-land-plot',
    name: 'Land/ Plot',
  },
  {
    key: 'buy-sell-office-shop',
    name: 'Office/ Shop',
  },
  {
    key: 'rent-house-villa',
    name: 'House/ Bungalow/ Villa',
  },
  {
    key: 'rent-office-shop',
    name: 'Office/ Shop',
  },
  {
    key: 'rent-pg-roommate',
    name: 'PG/ Roommate',
  },
  {
    key: 'rent-apartment-flat',
    name: 'Apartment/ Flat',
  },
  {
    key: 'buy-sell-vehicles-scooter',
    name: 'Scooter',
  },
  {
    key: 'buy-sell-vehicles-motorcycle-bike',
    name: 'Motorcycle',
  },
  {
    key: 'buy-sell-musical-instrument',
    name: 'Musical Instruments',
  },
  {
    key: 'buy-sell-computer-mobile-electronics',
    name: 'Computers/ Mobile/ Electronics and accessories',
  },
  {
    key: 'buy-sell-home-office-furniture',
    name: 'Furniture',
  },
  {
    key: 'buy-sell-appliances',
    name: 'Appliances',
  },
  {
    key: 'buy-sell-vehicles-car',
    name: 'Car/ SUV',
  },
];
export const META_SERVICES_LIST = [
  {
    key: 'used-car-scooter-motorcycle-dealers',
    name: 'Used Car/ Scooter/ Motorcycle Dealers',
  },
  {
    key: 'other_service_not_listed',
    name: 'Other (Service Not listed)',
  },
  {
    key: 'accounting-tax',
    name: 'Accounting/ Tax services',
  },
  {
    key: 'legal-services',
    name: 'Legal services',
  },
  {
    key: 'doctor-services',
    name: 'Doctors',
  },
  {
    key: 'pest-control',
    name: 'Pest Control',
  },
  {
    key: 'plumbing-services',
    name: 'Plumbing',
  },
  {
    key: 'specialized-jobs-carpenter',
    name: 'Carpenter',
  },
  {
    key: 'specialized-jobs-electrician',
    name: 'Electrician',
  },
  {
    key: 'painting-white-washing',
    name: 'Painting/ White washing',
  },
  {
    key: 'flooring-roofing-services',
    name: 'Flooring/ Roofing',
  },
  {
    key: 'pathology-diagnostic',
    name: 'Pathology/ Diagnostic centers',
  },
  {
    key: 'appliance-repairs',
    name: 'Machine/ Appliance repairs',
  },
  {
    key: 'laundry-service',
    name: 'Laundry services',
  },
  {
    key: 'auto-repair',
    name: 'Auto Repair',
  },
  {
    key: 'movers-packers',
    name: 'Movers/ Packers',
  },
  {
    key: 'shoe-repair',
    name: 'Shoe Repair',
  },
  {
    key: 'cooking-classes',
    name: 'Cooking Classes',
  },
  {
    key: 'language-classes',
    name: 'Language classes',
  },
  {
    key: 'salons-spas',
    name: 'Salons/ Spas',
  },
  {
    key: 'fitness-classes',
    name: 'Workouts/ Yoga',
  },
  {
    key: 'school-tuition-classes',
    name: 'School tuition/ Subject classes',
  },
  {
    key: 'hair-skin-care-services',
    name: 'Hair/ Skin care',
  },
  {
    key: 'dentistry-services',
    name: 'Dentistry',
  },
  {
    key: 'eye-care-services',
    name: 'Eye care',
  },
  {
    key: 'psychiatry-services',
    name: 'Psychiatry',
  },
  {
    key: 'tailoring-service',
    name: 'Tailoring/ Boutique',
  },
  {
    key: 'locksmith-service',
    name: 'Locksmith service',
  },
  {
    key: 'physiotherapy-chiropractor',
    name: 'Physiotherapist/ Chiropractor',
  },
  {
    key: 'maid-service',
    name: 'Maid/ Domestic help',
  },
  {
    key: 'photography-classes',
    name: 'Photography classes',
  },
  {
    key: 'beauty-makeup-classes',
    name: 'Beauty/ Makeup Classes',
  },
  {
    key: 'software-mobile-dev',
    name: 'Software/ Mobile App Dev Training',
  },
  {
    key: 'hobby-music-singing',
    name: 'Music Vocal/ Instrumental',
  },
  {
    key: 'gardening-landscaping',
    name: 'Gardening/ Landscaping',
  },
  {
    key: 'concrete-jobs',
    name: 'Mason/ Construction labor',
  },
  {
    key: 'acting-classes-drama-school',
    name: 'Acting Classes/Drama Schools',
  },
  {
    key: 'graphic-designing',
    name: 'Graphic Designing',
  },
  {
    key: 'video-audio-services',
    name: 'Video/ Audio Services',
  },
  {
    key: 'creative-technical-writing',
    name: 'Creative/ Technical Writing',
  },
  {
    key: 'web-designing',
    name: 'Web Designing',
  },
  {
    key: 'fencing-services',
    name: 'Fencing',
  },
  {
    key: 'other-party-entertainment',
    name: 'Party Entertainment',
  },
  {
    key: 'digital-marketing',
    name: 'Digital Marketing',
  },
  {
    key: 'cook-catering',
    name: 'Cooking service',
  },
  {
    key: 'hr-career-advance',
    name: 'Career development',
  },
  {
    key: 'ps-real-estate-agent-management',
    name: 'Real estate agent/ management',
  },
  {
    key: 'event-catering',
    name: 'Events/ Catering',
  },
  {
    key: 'hobby-decorative-art',
    name: 'Drawing/ Painting/ Sculpture',
  },
  {
    key: 'interior-design',
    name: 'Interior design/ decoration',
  },
  {
    key: 'hobby-dance',
    name: 'Dance classes',
  },
  {
    key: 'travel-service',
    name: 'Travel service',
  },
  {
    key: 'exam-coachings',
    name: 'Exam coachings',
  },
  {
    key: 'cloud-network-cert',
    name: 'Cloud/ Network Certifications',
  },
  {
    key: 'hobby-classes-film',
    name: 'Filmmaking classes',
  },
  {
    key: 'builder-architects',
    name: 'Builders/ Architects',
  },
  {
    key: 'mobile-computer-repair',
    name: 'Mobile/ Computer/ Electronics repair',
  },
  {
    key: 'alternative-therapy-medicine',
    name: 'Alternative Therapy/ Medicine',
  },
  {
    key: 'veterinary-services',
    name: 'Veterinary Services',
  },
  {
    key: 'security-guard-service',
    name: 'Security/ Guard service',
  },
  {
    key: 'pet-care',
    name: 'Pet care',
  },
  {
    key: 'sanitation-services',
    name: 'Sanitation services',
  },
  {
    key: 'domestic-delivery',
    name: 'Domestic delivery',
  },
  {
    key: 'ds-elderly-care',
    name: 'Elderly Care',
  },
  {
    key: 'ds-child-care',
    name: 'Child Care',
  },
  {
    key: 'loading-auto-services',
    name: 'Loading Auto services',
  },
  {
    key: 'hc-driving-classes',
    name: 'Driving classes',
  },
  {
    key: 'ps-insurance-services',
    name: 'Insurance services',
  },
  {
    key: 'fortune-telling-astrology',
    name: 'Fortune Telling/ Astrology',
  },
  {
    key: 'ds-driver-taxi',
    name: 'Driver/ Taxi service',
  },
];
export const META_DEALS_LIST = [
  {
    key: 'women-clothing',
    name: 'Women clothing',
  },
  {
    key: 'kids-clothing',
    name: 'Kids clothing',
  },
  {
    key: 'mens-clothing',
    name: 'Mens clothing',
  },
  {
    key: 'footwear-shoes',
    name: 'Footwear',
  },
  {
    key: 'home-decoration',
    name: 'Home Decoration',
  },
  {
    key: 'home-appliances',
    name: 'Appliances',
  },
  {
    key: 'jewellery-deals',
    name: 'Jewellery',
  },
  {
    key: 'computer-deals',
    name: 'Computers and accessories',
  },
  {
    key: 'watches-deals',
    name: 'Watches',
  },
  {
    key: 'mobile-phone-deals',
    name: 'Mobiles and accessories',
  },
  {
    key: 'sunglasses-deals',
    name: 'Sunglasses',
  },
  {
    key: 'home-office-furniture-deals',
    name: 'Home/ Office furniture',
  },
  {
    key: 'beauty-deals',
    name: 'Beauty',
  },
  {
    key: 'perfumes-deals',
    name: 'Perfumes',
  },
  {
    key: 'electronics-deals',
    name: 'Electronics',
  },
  {
    key: 'household-items',
    name: 'Household Items',
  },
];
