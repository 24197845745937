export const DEALS_LIST = [
  {
    key: 'women-clothing',
    name: 'Women clothing',
    category: 'clothes-jewellery-footwear',
    name_translations: [
      { language: 'english', value: 'Women clothing' },
      { language: 'hindi', value: 'महिला के कपड़े' },
    ],
    searchQuery: 'Women clothing deals/ discounts',
  },
  {
    key: 'kids-clothing',
    name: 'Kids clothing',
    category: 'clothes-jewellery-footwear',
    name_translations: [
      { language: 'english', value: 'Kids clothing' },
      { language: 'hindi', value: 'बच्चों के कपड़े' },
    ],
    searchQuery: 'Kids clothing deals/ discounts',
  },
  {
    key: 'mens-clothing',
    name: 'Mens clothing',
    category: 'clothes-jewellery-footwear',
    name_translations: [
      { language: 'english', value: 'Mens clothing' },
      { language: 'hindi', value: 'पुरुषों के लिए' },
    ],
    searchQuery: 'Mens clothing deals/ discounts',
  },
  {
    key: 'footwear-shoes',
    name: 'Footwear',
    category: 'clothes-jewellery-footwear',
    name_translations: [
      { language: 'english', value: 'Footwear' },
      { language: 'hindi', value: 'जूते' },
    ],
    searchQuery: 'Footwear deals and dicounts',
  },
  {
    key: 'home-decoration',
    name: 'Home Decoration',
    category: 'home-furniture-appliances',
    name_translations: [
      { language: 'english', value: 'Home Decoration' },
      { language: 'hindi', value: 'घर की सजावट' },
    ],
    searchQuery: 'Home decoration deals/ discounts',
  },
  {
    key: 'home-appliances',
    name: 'Appliances',
    category: 'home-furniture-appliances',
    name_translations: [
      { language: 'english', value: 'Appliances' },
      { language: 'hindi', value: 'उपकरण' },
    ],
    searchQuery: 'Appliances deals/ discounts',
  },
  {
    key: 'jewellery-deals',
    name: 'Jewellery',
    category: 'clothes-jewellery-footwear',
    name_translations: [
      { language: 'english', value: 'Jewellery' },
      { language: 'hindi', value: 'आभूषण' },
    ],
    searchQuery: 'Jewellery deals/ discounts',
  },
  {
    key: 'computer-deals',
    name: 'Computers and accessories',
    category: 'electronics-computers-mobile-phones',
    name_translations: [
      { language: 'english', value: 'Computers and accessories' },
      { language: 'hindi', value: 'कंप्यूटर और सहायक उपकरण' },
    ],
    searchQuery: 'Computers deals/ discounts',
  },
  {
    key: 'watches-deals',
    name: 'Watches',
    category: 'clothes-jewellery-footwear',
    name_translations: [
      { language: 'english', value: 'Watches' },
      { language: 'hindi', value: 'घड़ियों' },
    ],
    searchQuery: 'Watches deals/ discounts',
  },
  {
    key: 'mobile-phone-deals',
    name: 'Mobiles and accessories',
    category: 'electronics-computers-mobile-phones',
    name_translations: [
      { language: 'english', value: 'Mobiles and accessories' },
      { language: 'hindi', value: 'मोबाइल और एक्सेसरीज' },
    ],
    searchQuery: 'Mobile Phones deals/ discounts',
  },
  {
    key: 'sunglasses-deals',
    name: 'Sunglasses',
    category: 'beauty-perfumes-sunglasses',
    name_translations: [
      { language: 'english', value: 'Sunglasses' },
      { language: 'hindi', value: 'धूप का चश्मा' },
    ],
    searchQuery: 'Sunglasses deals/ discounts',
  },
  {
    key: 'home-office-furniture-deals',
    name: 'Home/ Office furniture',
    category: 'home-furniture-appliances',
    name_translations: [
      { language: 'english', value: 'Home/ Office furniture' },
      { language: 'hindi', value: 'घर/कार्यालय फर्नीचर' },
    ],
    searchQuery: 'Home/ Office furniture deals/ discounts',
  },
  {
    key: 'beauty-deals',
    name: 'Beauty',
    category: 'beauty-perfumes-sunglasses',
    name_translations: [
      { language: 'english', value: 'Beauty' },
      { language: 'hindi', value: 'सौंदर्य' },
    ],
    searchQuery: 'Beauty items deals/ discounts',
  },
  {
    key: 'perfumes-deals',
    name: 'Perfumes',
    category: 'beauty-perfumes-sunglasses',
    name_translations: [
      { language: 'english', value: 'Perfumes' },
      { language: 'hindi', value: 'इत्र' },
    ],
    searchQuery: 'Perfumes deals/ discounts',
  },
  {
    key: 'electronics-deals',
    name: 'Electronics',
    category: 'electronics-computers-mobile-phones',
    name_translations: [
      { language: 'english', value: 'Electronics' },
      { language: 'hindi', value: 'इलेक्ट्रॉनिक्स' },
    ],
    searchQuery: 'Electronics deals/ discounts',
  },
];
