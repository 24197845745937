import '../styles/app.scss';
import '../styles/globals.scss';
import Header from './Header';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Home from './Home';
import Gallery from './Gallery';

function App() {
  return (
    <div className='app container'>
      <Router>
        <Header />
        <Routes>
          <Route path='/gallery' element={<Gallery />} />
          <Route path='/' element={<Home />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
