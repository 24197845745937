export const CITIES = [
  {
    key: 'india-delhi-new-delhi',
    name: 'New Delhi',
    state: 'india-delhi',
  },
  {
    key: 'india-delhi-delhi',
    name: 'Delhi',
    state: 'india-delhi',
  },
  {
    key: 'india-mp-bhopal',
    name: 'Bhopal',
    state: 'india-mp',
  },
  {
    key: 'india-mp-indore',
    name: 'Indore',
    state: 'india-mp',
  },
  {
    key: 'india-gujrat-surat',
    name: 'Surat',
    state: 'india-gujrat',
  },
  {
    key: 'india-maharashtra-mumbai',
    name: 'Mumbai',
    state: 'india-maharashtra',
  },
  {
    key: 'india-karnataka-bengaluru',
    name: 'Bengaluru',
    state: 'india-karnataka',
  },
  {
    key: 'india-west-bengal-kolkata',
    name: 'Kolkata',
    state: 'india-west-bengal',
  },
  {
    key: 'india-tamil-nadu-chennai',
    name: 'Chennai',
    state: 'india-tamil-nadu',
  },
  {
    key: 'india-telangana-hyderabad',
    name: 'Hyderabad',
    state: 'india-telangana',
  },
  {
    key: 'india-maharashtra-pune',
    name: 'Pune',
    state: 'india-maharashtra',
  },
  {
    key: 'india-uttar-pradesh-kanpur',
    name: 'Kanpur',
    state: 'india-uttar-pradesh',
  },
  {
    key: 'india-rajasthan-jaipur',
    name: 'Jaipur',
    state: 'india-rajasthan',
  },
  {
    key: 'india-uttar-pradesh-lucknow',
    name: 'Lucknow',
    state: 'india-uttar-pradesh',
  },
  {
    key: 'india-maharashtra-nagpur',
    name: 'Nagpur',
    state: 'india-maharashtra',
  },
  {
    key: 'india-bihar-patna',
    name: 'Patna',
    state: 'india-bihar',
  },
  {
    key: 'india-punjab-ludhiana',
    name: 'Ludhiana',
    state: 'india-punjab',
  },
  {
    key: 'india-tamil-nadu-tirunelveli',
    name: 'Tirunelveli',
    state: 'india-tamil-nadu',
  },
  {
    key: 'india-uttar-pradesh-agra',
    name: 'Agra',
    state: 'india-uttar-pradesh',
  },
  {
    key: 'india-gujrat-vadodara',
    name: 'Vadodara',
    state: 'india-gujrat',
  },
  {
    key: 'india-karnataka-udupi',
    name: 'Udupi',
    state: 'india-karnataka',
  },
  {
    key: 'india-west-bengal-uluberia',
    name: 'Uluberia',
    state: 'india-west-bengal',
  },
  {
    key: 'india-maharashtra-ulhasnagar',
    name: 'Ulhasnagar',
    state: 'india-maharashtra',
  },
  {
    key: 'india-uttar-pradesh-unnao',
    name: 'Unnao',
    state: 'india-uttar-pradesh',
  },
  {
    key: 'india-andhra-pradesh-visakhapatnam',
    name: 'Visakhapatnam',
    state: 'india-andhra-pradesh',
  },
  {
    key: 'india-maharashtra-jalgaon',
    name: 'Jalgaon',
    state: 'india-maharashtra',
  },
  {
    key: 'india-tamil-nadu-tiruvottiyur',
    name: 'Tiruvottiyur',
    state: 'india-tamil-nadu',
  },
  {
    key: 'india-west-bengal-titagarh',
    name: 'Titagarh',
    state: 'india-west-bengal',
  },
  {
    key: 'india-maharashtra-udgir',
    name: 'Udgir',
    state: 'india-maharashtra',
  },
  {
    key: 'india-karnataka-tumkur',
    name: 'Tumkur',
    state: 'india-karnataka',
  },
  {
    key: 'india-west-bengal-uttarpara-kotrung',
    name: 'Uttarpara Kotrung',
    state: 'india-west-bengal',
  },
  {
    key: 'india-gujrat-valsad',
    name: 'Valsad',
    state: 'india-gujrat',
  },
  {
    key: 'india-gujrat-veraval',
    name: 'Veraval',
    state: 'india-gujrat',
  },
  {
    key: 'india-mp-vidisha',
    name: 'Vidisha',
    state: 'india-mp',
  },
  {
    key: 'india-mp-jabalpur',
    name: 'Jabalpur',
    state: 'india-mp',
  },
  {
    key: 'india-maharashtra-vasai-virar-city',
    name: 'Vasai Virar City',
    state: 'india-maharashtra',
  },
  {
    key: 'india-tamil-nadu-tiruppur',
    name: 'Tiruppur',
    state: 'india-tamil-nadu',
  },
  {
    key: 'india-haryana-yamunanagar',
    name: 'Yamunanagar',
    state: 'india-haryana',
  },
  {
    key: 'india-himachal-shimla',
    name: 'Shimla',
    state: 'india-himachal',
  },
  {
    key: 'india-maharashtra-wardha',
    name: 'Wardha',
    state: 'india-maharashtra',
  },
  {
    key: 'india-uttar-pradesh-moradabad',
    name: 'Moradabad',
    state: 'india-uttar-pradesh',
  },
  {
    key: 'india-maharashtra-kolhapur',
    name: 'Kolhapur',
    state: 'india-maharashtra',
  },
  {
    key: 'india-tamil-nadu-tiruchirappalli',
    name: 'Tiruchirappalli',
    state: 'india-tamil-nadu',
  },
  {
    key: 'india-rajasthan-bikaner',
    name: 'Bikaner',
    state: 'india-rajasthan',
  },
  {
    key: 'india-maharashtra-yavatmal',
    name: 'Yavatmal',
    state: 'india-maharashtra',
  },
  {
    key: 'india-andhra-pradesh-vijayawada',
    name: 'Vijayawada',
    state: 'india-andhra-pradesh',
  },
  {
    key: 'india-goa-panaji',
    name: 'Panaji',
    state: 'india-goa',
  },
  {
    key: 'india-karnataka-mysore',
    name: 'Mysore',
    state: 'india-karnataka',
  },
  {
    key: 'india-uttar-pradesh-meerut',
    name: 'Meerut',
    state: 'india-uttar-pradesh',
  },
  {
    key: 'india-kerala-kochi',
    name: 'Kochi',
    state: 'india-kerala',
  },
  {
    key: 'india-west-bengal-asansol',
    name: 'Asansol',
    state: 'india-west-bengal',
  },
  {
    key: 'india-karnataka-belgaum',
    name: 'Belgaum',
    state: 'india-karnataka',
  },
  {
    key: 'india-uttar-pradesh-aligarh',
    name: 'Aligarh',
    state: 'india-uttar-pradesh',
  },
  {
    key: 'india-dadar-nagar-haveli-daman-diu-daman',
    name: 'Daman',
    state: 'india-dadar-nagar-haveli-daman-diu',
  },
  {
    key: 'india-karnataka-davangere',
    name: 'Davangere',
    state: 'india-karnataka',
  },
  {
    key: 'india-uttarakhand-dehradun',
    name: 'DehraDun',
    state: 'india-uttarakhand',
  },
  {
    key: 'india-dadar-nagar-haveli-daman-diu-diu',
    name: 'Diu',
    state: 'india-dadar-nagar-haveli-daman-diu',
  },
  {
    key: 'india-bihar-gaya',
    name: 'Gaya',
    state: 'india-bihar',
  },
  {
    key: 'india-maharashtra-bhiwandi',
    name: 'Bhiwandi',
    state: 'india-maharashtra',
  },
  {
    key: 'india-karnataka-gulbarga',
    name: 'Gulbarga',
    state: 'india-karnataka',
  },
  {
    key: 'india-uttar-pradesh-firozabad',
    name: 'Firozabad',
    state: 'india-uttar-pradesh',
  },
  {
    key: 'india-assam-guwahati',
    name: 'Guwahati',
    state: 'india-assam',
  },
  {
    key: 'india-rajasthan-jodhpur',
    name: 'Jodhpur',
    state: 'india-rajasthan',
  },
  {
    key: 'india-uttar-pradesh-jhansi',
    name: 'Jhansi',
    state: 'india-uttar-pradesh',
  },
  {
    key: 'india-andhra-pradesh-kakinada',
    name: 'Kakinada',
    state: 'india-andhra-pradesh',
  },
  {
    key: 'india-maharashtra-khanapur',
    name: 'Khanapur',
    state: 'india-maharashtra',
  },
  {
    key: 'india-karnataka-kolar',
    name: 'Kolar',
    state: 'india-karnataka',
  },
  {
    key: 'india-tamil-nadu-krishnapuram',
    name: 'Krishnapuram',
    state: 'india-tamil-nadu',
  },
  {
    key: 'india-rajasthan-kota',
    name: 'Kota',
    state: 'india-rajasthan',
  },
  {
    key: 'india-andhra-pradesh-kurnool',
    name: 'Kurnool',
    state: 'india-andhra-pradesh',
  },
  {
    key: 'india-karnataka-mangalore',
    name: 'Mangalore',
    state: 'india-karnataka',
  },
  {
    key: 'india-maharashtra-nanded',
    name: 'Nanded',
    state: 'india-maharashtra',
  },
  {
    key: 'india-andhra-pradesh-nellore',
    name: 'Nellore',
    state: 'india-andhra-pradesh',
  },
  {
    key: 'india-punjab-patiala',
    name: 'Patiala',
    state: 'india-punjab',
  },
  {
    key: 'india-punjab-pathankot',
    name: 'Pathankot',
    state: 'india-punjab',
  },
  {
    key: 'india-haryana-panipat',
    name: 'Panipat',
    state: 'india-haryana',
  },
  {
    key: 'india-maharashtra-parbhani',
    name: 'Parbhani',
    state: 'india-maharashtra',
  },
  {
    key: 'india-haryana-panchkula',
    name: 'Panchkula',
    state: 'india-haryana',
  },
  {
    key: 'india-jk-srinagar',
    name: 'Srinagar',
    state: 'india-jk',
  },
  {
    key: 'india-gujrat-porbandar',
    name: 'Porbandar',
    state: 'india-gujrat',
  },
  {
    key: 'india-uttar-pradesh-pilibhit',
    name: 'Pilibhit',
    state: 'india-uttar-pradesh',
  },
  {
    key: 'india-andhra-pradesh-proddatur',
    name: 'Proddatur',
    state: 'india-andhra-pradesh',
  },
  {
    key: 'india-jharkhand-jamshedpur',
    name: 'Jamshedpur',
    state: 'india-jharkhand',
  },
  {
    key: 'india-chattisgarh-raipur',
    name: 'Raipur',
    state: 'india-chattisgarh',
  },
  {
    key: 'india-karnataka-raichur',
    name: 'Raichur',
    state: 'india-karnataka',
  },
  {
    key: 'india-puducherry-puducherry',
    name: 'Puducherry',
    state: 'india-puducherry',
  },
  {
    key: 'india-bihar-purnea',
    name: 'Purnea',
    state: 'india-bihar',
  },
  {
    key: 'india-rajasthan-rampura',
    name: 'Rampura',
    state: 'india-rajasthan',
  },
  {
    key: 'india-odisha-puri',
    name: 'Puri',
    state: 'india-odisha',
  },
  {
    key: 'india-tamil-nadu-rajapalaiyam',
    name: 'Rajapalaiyam',
    state: 'india-tamil-nadu',
  },
  {
    key: 'india-odisha-samlaipadar',
    name: 'Samlaipadar',
    state: 'india-odisha',
  },
  {
    key: 'india-odisha-raurkela',
    name: 'Raurkela',
    state: 'india-odisha',
  },
  {
    key: 'india-maharashtra-sangli',
    name: 'Sangli',
    state: 'india-maharashtra',
  },
  {
    key: 'india-mp-ratlam',
    name: 'Ratlam',
    state: 'india-mp',
  },
  {
    key: 'india-uttar-pradesh-saidapur',
    name: 'Saidapur',
    state: 'india-uttar-pradesh',
  },
  {
    key: 'india-jk-saidpur',
    name: 'Saidpur',
    state: 'india-jk',
  },
  {
    key: 'india-tamil-nadu-salem',
    name: 'Salem',
    state: 'india-tamil-nadu',
  },
  {
    key: 'india-mp-saugor',
    name: 'Saugor',
    state: 'india-mp',
  },
  {
    key: 'india-uttar-pradesh-shahbazpur',
    name: 'Shahbazpur',
    state: 'india-uttar-pradesh',
  },
  {
    key: 'india-west-bengal-shiliguri',
    name: 'Shiliguri',
    state: 'india-west-bengal',
  },
  {
    key: 'india-uttar-pradesh-saharanpur',
    name: 'Saharanpur',
    state: 'india-uttar-pradesh',
  },
  {
    key: 'india-haryana-sirsa',
    name: 'Sirsa',
    state: 'india-haryana',
  },
  {
    key: 'india-rajasthan-sikar',
    name: 'Sikar',
    state: 'india-rajasthan',
  },
  {
    key: 'india-assam-silchar',
    name: 'Silchar',
    state: 'india-assam',
  },
  {
    key: 'india-haryana-sonipat',
    name: 'Sonipat',
    state: 'india-haryana',
  },
  {
    key: 'india-tamil-nadu-thanjavur',
    name: 'Thanjavur',
    state: 'india-tamil-nadu',
  },
  {
    key: 'india-uttar-pradesh-tharati-etawah',
    name: 'Tharati Etawah',
    state: 'india-uttar-pradesh',
  },
  {
    key: 'india-meghalaya-shillong',
    name: 'Shillong',
    state: 'india-meghalaya',
  },
  {
    key: 'india-assam-tezpur',
    name: 'Tezpur',
    state: 'india-assam',
  },
  {
    key: 'india-andhra-pradesh-tirupati',
    name: 'Tirupati',
    state: 'india-andhra-pradesh',
  },
  {
    key: 'india-dadar-nagar-haveli-daman-diu-silvassa',
    name: 'Silvassa',
    state: 'india-dadar-nagar-haveli-daman-diu',
  },
  {
    key: 'india-haryana-faridabad',
    name: 'Faridabad',
    state: 'india-haryana',
  },
  {
    key: 'india-uttar-pradesh-ghaziabad',
    name: 'Ghaziabad',
    state: 'india-uttar-pradesh',
  },
  {
    key: 'india-tamil-nadu-tiruvannamalai',
    name: 'Tiruvannamalai',
    state: 'india-tamil-nadu',
  },
  {
    key: 'india-andhra-pradesh-vishakhapatnam',
    name: 'Visakhapatnam',
    state: 'india-andhra-pradesh',
  },
  {
    key: 'india-mp-ujjain',
    name: 'Ujjain',
    state: 'india-mp',
  },
  {
    key: 'india-tamil-nadu-tuticorin',
    name: 'Tuticorin',
    state: 'india-tamil-nadu',
  },
  {
    key: 'india-rajasthan-udaipur',
    name: 'Udaipur',
    state: 'india-rajasthan',
  },
  {
    key: 'india-rajasthan-tonk',
    name: 'Tonk',
    state: 'india-rajasthan',
  },
  {
    key: 'india-andhra-pradesh-vizianagaram',
    name: 'Vizianagaram',
    state: 'india-andhra-pradesh',
  },
  {
    key: 'india-uttar-pradesh-varanasi',
    name: 'Varanasi',
    state: 'india-uttar-pradesh',
  },
  {
    key: 'india-kerala-thiruvananthapuram',
    name: 'Thiruvananthapuram',
    state: 'india-kerala',
  },
  {
    key: 'india-tamil-nadu-vellore',
    name: 'Vellore',
    state: 'india-tamil-nadu',
  },
  {
    key: 'india-tamil-nadu-valparai',
    name: 'Valparai',
    state: 'india-tamil-nadu',
  },
  {
    key: 'india-odisha-brajrajnagar',
    name: 'Brajrajnagar',
    state: 'india-odisha',
  },
  {
    key: 'india-odisha-talcher',
    name: 'Talcher',
    state: 'india-odisha',
  },
  {
    key: 'india-jharkhand-ranchi',
    name: 'Ranchi',
    state: 'india-jharkhand',
  },
  {
    key: 'india-jharkhand-adityapur',
    name: 'Adityapur',
    state: 'india-jharkhand',
  },
  {
    key: 'india-tamil-nadu-ambattur',
    name: 'Ambattur',
    state: 'india-tamil-nadu',
  },
  {
    key: 'india-uttar-pradesh-azamgarh',
    name: 'Azamgarh',
    state: 'india-uttar-pradesh',
  },
  {
    key: 'india-odisha-baleshwar-town',
    name: 'Baleshwar Town',
    state: 'india-odisha',
  },
  {
    key: 'india-karnataka-bijapur',
    name: 'Bijapur',
    state: 'india-karnataka',
  },
  {
    key: 'india-uttar-pradesh-gopalpur',
    name: 'Gopalpur',
    state: 'india-uttar-pradesh',
  },
  {
    key: 'india-kerala-kollam',
    name: 'Kollam',
    state: 'india-kerala',
  },
  {
    key: 'india-maharashtra-latur',
    name: 'Latur',
    state: 'india-maharashtra',
  },
  {
    key: 'india-uttar-pradesh-mathura',
    name: 'Mathura',
    state: 'india-uttar-pradesh',
  },
  {
    key: 'india-uttar-pradesh-muzaffarnagar',
    name: 'Muzaffarnagar',
    state: 'india-uttar-pradesh',
  },
  {
    key: 'india-bihar-muzaffarpur',
    name: 'Muzaffarpur',
    state: 'india-bihar',
  },
  {
    key: 'india-telangana-nizamabad',
    name: 'Nizamabad',
    state: 'india-telangana',
  },
  {
    key: 'india-haryana-rohtak',
    name: 'Rohtak',
    state: 'india-haryana',
  },
  {
    key: 'india-karnataka-shimoga',
    name: 'Shimoga',
    state: 'india-karnataka',
  },
  {
    key: 'india-jharkhand-jorapokhar',
    name: 'Jorapokhar',
    state: 'india-jharkhand',
  },
  {
    key: 'india-telangana-warangal',
    name: 'Warangal',
    state: 'india-telangana',
  },
  {
    key: 'india-maharashtra-achalpur',
    name: 'Achalpur',
    state: 'india-maharashtra',
  },
  {
    key: 'india-andhra-pradesh-chilakaluripet',
    name: 'Chilakaluripet',
    state: 'india-andhra-pradesh',
  },
  {
    key: 'india-rajasthan-chittaurgarh',
    name: 'Chittaurgarh',
    state: 'india-rajasthan',
  },
  {
    key: 'india-jharkhand-deoghar',
    name: 'Deoghar',
    state: 'india-jharkhand',
  },
  {
    key: 'india-tamil-nadu-erode',
    name: 'Erode',
    state: 'india-tamil-nadu',
  },
  {
    key: 'india-uttar-pradesh-farrukhabad-cum-fatehgarh',
    name: 'Farrukhabad-cum-Fatehgarh',
    state: 'india-uttar-pradesh',
  },
  {
    key: 'india-rajasthan-banswara',
    name: 'Banswara',
    state: 'india-rajasthan',
  },
  {
    key: 'india-odisha-baripada-town',
    name: 'Baripada Town',
    state: 'india-odisha',
  },
  {
    key: 'india-west-bengal-basirhat',
    name: 'Basirhat',
    state: 'india-west-bengal',
  },
  {
    key: 'india-karnataka-bhadravati',
    name: 'Bhadravati',
    state: 'india-karnataka',
  },
  {
    key: 'india-odisha-brahmapur-town',
    name: 'Brahmapur Town',
    state: 'india-odisha',
  },
  {
    key: 'india-maharashtra-chandrapur',
    name: 'Chandrapur',
    state: 'india-maharashtra',
  },
  {
    key: 'india-andhra-pradesh-adoni',
    name: 'Adoni',
    state: 'india-andhra-pradesh',
  },
  {
    key: 'india-haryana-ambala-sadar',
    name: 'Ambala Sadar',
    state: 'india-haryana',
  },
  {
    key: 'india-uttar-pradesh-akbarpur',
    name: 'Akbarpur',
    state: 'india-uttar-pradesh',
  },
  {
    key: 'india-tamil-nadu-alandur',
    name: 'Alandur',
    state: 'india-tamil-nadu',
  },
  {
    key: 'india-maharashtra-ambarnath',
    name: 'Ambarnath',
    state: 'india-maharashtra',
  },
  {
    key: 'india-chattisgarh-ambikapur',
    name: 'Ambikapur',
    state: 'india-chattisgarh',
  },
  {
    key: 'india-tamil-nadu-ambur',
    name: 'Ambur',
    state: 'india-tamil-nadu',
  },
  {
    key: 'india-gujrat-anand',
    name: 'Anand',
    state: 'india-gujrat',
  },
  {
    key: 'india-gujrat-amreli',
    name: 'Amreli',
    state: 'india-gujrat',
  },
  {
    key: 'india-jk-anantnag',
    name: 'Anantnag',
    state: 'india-jk',
  },
  {
    key: 'india-andhra-pradesh-anantapur',
    name: 'Anantapur',
    state: 'india-andhra-pradesh',
  },
  {
    key: 'india-bihar-arrah',
    name: 'Arrah',
    state: 'india-bihar',
  },
  {
    key: 'india-tamil-nadu-avadi',
    name: 'Avadi',
    state: 'india-tamil-nadu',
  },
  {
    key: 'india-uttar-pradesh-amroha',
    name: 'Amroha',
    state: 'india-uttar-pradesh',
  },
  {
    key: 'india-west-bengal-ashoknagar-kalyangarh',
    name: 'Ashoknagar Kalyangarh',
    state: 'india-west-bengal',
  },
  {
    key: 'india-mp-ashoknagar-',
    name: 'Ashoknagar ',
    state: 'india-mp',
  },
  {
    key: 'india-haryana-bahadurgarh',
    name: 'Bahadurgarh',
    state: 'india-haryana',
  },
  {
    key: 'india-maharashtra-badlapur',
    name: 'Badlapur',
    state: 'india-maharashtra',
  },
  {
    key: 'india-bihar-bagaha',
    name: 'Bagaha',
    state: 'india-bihar',
  },
  {
    key: 'india-karnataka-bagalkot',
    name: 'Bagalkot',
    state: 'india-karnataka',
  },
  {
    key: 'india-west-bengal-baharampur',
    name: 'Baharampur',
    state: 'india-west-bengal',
  },
  {
    key: 'india-west-bengal-bally',
    name: 'Bally',
    state: 'india-west-bengal',
  },
  {
    key: 'india-west-bengal-baidyabati',
    name: 'Baidyabati',
    state: 'india-west-bengal',
  },
  {
    key: 'india-uttar-pradesh-bahraich',
    name: 'Bahraich',
    state: 'india-uttar-pradesh',
  },
  {
    key: 'india-uttar-pradesh-ballia',
    name: 'Ballia',
    state: 'india-uttar-pradesh',
  },
  {
    key: 'india-west-bengal-balurghat',
    name: 'Balurghat',
    state: 'india-west-bengal',
  },
  {
    key: 'india-west-bengal-bankura',
    name: 'Bankura',
    state: 'india-west-bengal',
  },
  {
    key: 'india-uttar-pradesh-banda',
    name: 'Banda',
    state: 'india-uttar-pradesh',
  },
  {
    key: 'india-rajasthan-baran',
    name: 'Baran',
    state: 'india-rajasthan',
  },
  {
    key: 'india-west-bengal-bansberia',
    name: 'Bansberia',
    state: 'india-west-bengal',
  },
  {
    key: 'india-west-bengal-baranagar',
    name: 'Baranagar',
    state: 'india-west-bengal',
  },
  {
    key: 'india-punjab-barnala',
    name: 'Barnala',
    state: 'india-punjab',
  },
  {
    key: 'india-uttar-pradesh-baraut',
    name: 'Baraut',
    state: 'india-uttar-pradesh',
  },
  {
    key: 'india-west-bengal-barasat',
    name: 'Barasat',
    state: 'india-west-bengal',
  },
  {
    key: 'india-west-bengal-barrackpur',
    name: 'Barrackpur',
    state: 'india-west-bengal',
  },
  {
    key: 'india-punjab-batala',
    name: 'Batala',
    state: 'india-punjab',
  },
  {
    key: 'india-uttar-pradesh-basti',
    name: 'Basti',
    state: 'india-uttar-pradesh',
  },
  {
    key: 'india-maharashtra-barshi',
    name: 'Barshi',
    state: 'india-maharashtra',
  },
  {
    key: 'india-punjab-bathinda',
    name: 'Bathinda',
    state: 'india-punjab',
  },
  {
    key: 'india-rajasthan-beawar',
    name: 'Beawar',
    state: 'india-rajasthan',
  },
  {
    key: 'india-bihar-begusarai',
    name: 'Begusarai',
    state: 'india-bihar',
  },
  {
    key: 'india-west-bengal-bhadreswar',
    name: 'Bhadreswar',
    state: 'india-west-bengal',
  },
  {
    key: 'india-odisha-bhadrak',
    name: 'Bhadrak',
    state: 'india-odisha',
  },
  {
    key: 'india-mp-betul',
    name: 'Betul',
    state: 'india-mp',
  },
  {
    key: 'india-bihar-bettiah',
    name: 'Bettiah',
    state: 'india-bihar',
  },
  {
    key: 'india-gujrat-bharuch',
    name: 'Bharuch',
    state: 'india-gujrat',
  },
  {
    key: 'india-mp-bhind',
    name: 'Bhind',
    state: 'india-mp',
  },
  {
    key: 'india-rajasthan-bhiwadi',
    name: 'Bhiwadi',
    state: 'india-rajasthan',
  },
  {
    key: 'india-maharashtra-bhusawal',
    name: 'Bhusawal',
    state: 'india-maharashtra',
  },
  {
    key: 'india-andhra-pradesh-bhimavaram',
    name: 'Bhimavaram',
    state: 'india-andhra-pradesh',
  },
  {
    key: 'india-west-bengal-bidhan-nagar',
    name: 'Bidhan Nagar',
    state: 'india-west-bengal',
  },
  {
    key: 'india-rajasthan-bundi',
    name: 'Bundi',
    state: 'india-rajasthan',
  },
  {
    key: 'india-gujrat-botad',
    name: 'Botad',
    state: 'india-gujrat',
  },
  {
    key: 'india-jharkhand-bokaro-steel-city',
    name: 'Bokaro Steel City',
    state: 'india-jharkhand',
  },
  {
    key: 'india-west-bengal-champdani',
    name: 'Champdani',
    state: 'india-west-bengal',
  },
  {
    key: 'india-bihar-buxar',
    name: 'Buxar',
    state: 'india-bihar',
  },
  {
    key: 'india-mp-burhanpur',
    name: 'Burhanpur',
    state: 'india-mp',
  },
  {
    key: 'india-west-bengal-chandannagar',
    name: 'Chandannagar',
    state: 'india-west-bengal',
  },
  {
    key: 'india-west-bengal-bongaon',
    name: 'Bongaon',
    state: 'india-west-bengal',
  },
  {
    key: 'india-uttar-pradesh-chandausi',
    name: 'Chandausi',
    state: 'india-uttar-pradesh',
  },
  {
    key: 'india-bihar-chapra',
    name: 'Chapra',
    state: 'india-bihar',
  },
  {
    key: 'india-mp-chhattarpur',
    name: 'Chhattarpur',
    state: 'india-mp',
  },
  {
    key: 'india-mp-chhindwara',
    name: 'Chhindwara',
    state: 'india-mp',
  },
  {
    key: 'india-jharkhand-chas',
    name: 'Chas',
    state: 'india-jharkhand',
  },
  {
    key: 'india-karnataka-chikmagalur',
    name: 'Chikmagalur',
    state: 'india-karnataka',
  },
  {
    key: 'india-maharashtra-greater-mumbai',
    name: 'Greater Mumbai',
    state: 'india-maharashtra',
  },
  {
    key: 'india-uttar-pradesh-gorakhpur',
    name: 'Gorakhpur',
    state: 'india-uttar-pradesh',
  },
  {
    key: 'india-gujrat-gandhinagar',
    name: 'Gandhinagar',
    state: 'india-gujrat',
  },
  {
    key: 'india-karnataka-chitradurga',
    name: 'Chitradurga',
    state: 'india-karnataka',
  },
  {
    key: 'india-andhra-pradesh-chittoor',
    name: 'Chittoor',
    state: 'india-andhra-pradesh',
  },
  {
    key: 'india-rajasthan-churu',
    name: 'Churu',
    state: 'india-rajasthan',
  },
  {
    key: 'india-mp-damoh',
    name: 'Damoh',
    state: 'india-mp',
  },
  {
    key: 'india-west-bengal-darjiling',
    name: 'Darjiling',
    state: 'india-west-bengal',
  },
  {
    key: 'india-west-bengal-dabgram',
    name: 'Dabgram',
    state: 'india-west-bengal',
  },
  {
    key: 'india-bihar-darbhanga',
    name: 'Darbhanga',
    state: 'india-bihar',
  },
  {
    key: 'india-mp-datia',
    name: 'Datia',
    state: 'india-mp',
  },
  {
    key: 'india-karnataka-davanagere',
    name: 'Davanagere',
    state: 'india-karnataka',
  },
  {
    key: 'india-bihar-dehri',
    name: 'Dehri',
    state: 'india-bihar',
  },
  {
    key: 'india-gujrat-deesa',
    name: 'Deesa',
    state: 'india-gujrat',
  },
  {
    key: 'india-mp-dewas',
    name: 'Dewas',
    state: 'india-mp',
  },
  {
    key: 'india-rajasthan-dhaulpur',
    name: 'Dhaulpur',
    state: 'india-rajasthan',
  },
  {
    key: 'india-west-bengal-dum-dum',
    name: 'Dum Dum',
    state: 'india-west-bengal',
  },
  {
    key: 'india-nagaland-dimapur',
    name: 'Dimapur',
    state: 'india-nagaland',
  },
  {
    key: 'india-maharashtra-dhule',
    name: 'Dhule',
    state: 'india-maharashtra',
  },
  {
    key: 'india-andhra-pradesh-eluru',
    name: 'Eluru',
    state: 'india-andhra-pradesh',
  },
  {
    key: 'india-west-bengal-english-bazar',
    name: 'English Bazar',
    state: 'india-west-bengal',
  },
  {
    key: 'india-chattisgarh-durg',
    name: 'Durg',
    state: 'india-chattisgarh',
  },
  {
    key: 'india-uttar-pradesh-faizabad',
    name: 'Faizabad',
    state: 'india-uttar-pradesh',
  },
  {
    key: 'india-uttar-pradesh-etah',
    name: 'Etah',
    state: 'india-uttar-pradesh',
  },
  {
    key: 'india-andhra-pradesh-dharmavaram',
    name: 'Dharmavaram',
    state: 'india-andhra-pradesh',
  },
  {
    key: 'india-west-bengal-durgapur',
    name: 'Durgapur',
    state: 'india-west-bengal',
  },
  {
    key: 'india-uttar-pradesh-etawah',
    name: 'Etawah',
    state: 'india-uttar-pradesh',
  },
  {
    key: 'india-gujrat-gandhidham',
    name: 'Gandhidham',
    state: 'india-gujrat',
  },
  {
    key: 'india-punjab-firozpur',
    name: 'Firozpur',
    state: 'india-punjab',
  },
  {
    key: 'india-jharkhand-giridih',
    name: 'Giridih',
    state: 'india-jharkhand',
  },
  {
    key: 'india-gujrat-gondal',
    name: 'Gondal',
    state: 'india-gujrat',
  },
  {
    key: 'india-maharashtra-gondiya',
    name: 'Gondiya',
    state: 'india-maharashtra',
  },
  {
    key: 'india-punjab-jalandhar',
    name: 'Jalandhar',
    state: 'india-punjab',
  },
  {
    key: 'india-chattisgarh-jagdalpur',
    name: 'Jagdalpur',
    state: 'india-chattisgarh',
  },
  {
    key: 'india-uttarakhand-hardwar',
    name: 'Hardwar',
    state: 'india-uttarakhand',
  },
  {
    key: 'india-andhra-pradesh-gudivada',
    name: 'Gudivada',
    state: 'india-andhra-pradesh',
  },
  {
    key: 'india-punjab-hoshiarpur',
    name: 'Hoshiarpur',
    state: 'india-punjab',
  },
  {
    key: 'india-west-bengal-habra',
    name: 'Habra',
    state: 'india-west-bengal',
  },
  {
    key: 'india-mp-guna',
    name: 'Guna',
    state: 'india-mp',
  },
  {
    key: 'india-west-bengal-halisahar',
    name: 'Halisahar',
    state: 'india-west-bengal',
  },
  {
    key: 'india-uttar-pradesh-hardoi',
    name: 'Hardoi',
    state: 'india-uttar-pradesh',
  },
  {
    key: 'india-rajasthan-hanumangarh',
    name: 'Hanumangarh',
    state: 'india-rajasthan',
  },
  {
    key: 'india-karnataka-hassan',
    name: 'Hassan',
    state: 'india-karnataka',
  },
  {
    key: 'india-jharkhand-hazaribag',
    name: 'Hazaribag',
    state: 'india-jharkhand',
  },
  {
    key: 'india-rajasthan-hindaun',
    name: 'Hindaun',
    state: 'india-rajasthan',
  },
  {
    key: 'india-uttar-pradesh-hathras',
    name: 'Hathras',
    state: 'india-uttar-pradesh',
  },
  {
    key: 'india-maharashtra-hinganghat',
    name: 'Hinganghat',
    state: 'india-maharashtra',
  },
  {
    key: 'india-tamil-nadu-hosur',
    name: 'Hosur',
    state: 'india-tamil-nadu',
  },
  {
    key: 'india-mp-hoshangabad',
    name: 'Hoshangabad',
    state: 'india-mp',
  },
  {
    key: 'india-west-bengal-hugli-chinsurah',
    name: 'Hugli-Chinsurah',
    state: 'india-west-bengal',
  },
  {
    key: 'india-haryana-jagadhri',
    name: 'Jagadhri',
    state: 'india-haryana',
  },
  {
    key: 'india-maharashtra-ichalkaranji',
    name: 'Ichalkaranji',
    state: 'india-maharashtra',
  },
  {
    key: 'india-maharashtra-jalna',
    name: 'Jalna',
    state: 'india-maharashtra',
  },
  {
    key: 'india-west-bengal-jamuria',
    name: 'Jamuria',
    state: 'india-west-bengal',
  },
  {
    key: 'india-uttar-pradesh-jaunpur',
    name: 'Jaunpur',
    state: 'india-uttar-pradesh',
  },
  {
    key: 'india-gujrat-jamnagar',
    name: 'Jamnagar',
    state: 'india-gujrat',
  },
  {
    key: 'india-bihar-jehanabad',
    name: 'Jehanabad',
    state: 'india-bihar',
  },
  {
    key: 'india-rajasthan-jhunjhunun',
    name: 'Jhunjhunun',
    state: 'india-rajasthan',
  },
  {
    key: 'india-haryana-jind',
    name: 'Jind',
    state: 'india-haryana',
  },
  {
    key: 'india-gujrat-junagadh',
    name: 'Junagadh',
    state: 'india-gujrat',
  },
  {
    key: 'india-gujrat-jetpur-navagadh',
    name: 'Jetpur Navagadh',
    state: 'india-gujrat',
  },
  {
    key: 'india-andhra-pradesh-kadapa',
    name: 'Kadapa',
    state: 'india-andhra-pradesh',
  },
  {
    key: 'india-west-bengal-kamarhati',
    name: 'Kamarhati',
    state: 'india-west-bengal',
  },
  {
    key: 'india-gujrat-kalol',
    name: 'Kalol',
    state: 'india-gujrat',
  },
  {
    key: 'india-west-bengal-kalyani',
    name: 'Kalyani',
    state: 'india-west-bengal',
  },
  {
    key: 'india-haryana-kaithal',
    name: 'Kaithal',
    state: 'india-haryana',
  },
  {
    key: 'india-uttarakhand-kashipur',
    name: 'Kashipur',
    state: 'india-uttarakhand',
  },
  {
    key: 'india-uttar-pradesh-kasganj',
    name: 'Kasganj',
    state: 'india-uttar-pradesh',
  },
  {
    key: 'india-west-bengal-kanchrapara',
    name: 'Kanchrapara',
    state: 'india-west-bengal',
  },
  {
    key: 'india-mp-khandwa',
    name: 'Khandwa',
    state: 'india-mp',
  },
  {
    key: 'india-bihar-katihar',
    name: 'Katihar',
    state: 'india-bihar',
  },
  {
    key: 'india-punjab-khanna',
    name: 'Khanna',
    state: 'india-punjab',
  },
  {
    key: 'india-west-bengal-khardaha',
    name: 'Khardaha',
    state: 'india-west-bengal',
  },
  {
    key: 'india-bihar-kishanganj',
    name: 'Kishanganj',
    state: 'india-bihar',
  },
  {
    key: 'india-chattisgarh-korba',
    name: 'Korba',
    state: 'india-chattisgarh',
  },
  {
    key: 'india-tamil-nadu-kurichi',
    name: 'Kurichi',
    state: 'india-tamil-nadu',
  },
  {
    key: 'india-west-bengal-madhyamgram',
    name: 'Madhyamgram',
    state: 'india-west-bengal',
  },
  {
    key: 'india-andhra-pradesh-mahbubnagar',
    name: 'Mahbubnagar',
    state: 'india-andhra-pradesh',
  },
  {
    key: 'india-uttar-pradesh-mainpuri',
    name: 'Mainpuri',
    state: 'india-uttar-pradesh',
  },
  {
    key: 'india-mp-mandsaur',
    name: 'Mandsaur',
    state: 'india-mp',
  },
  {
    key: 'india-karnataka-mandya',
    name: 'Mandya',
    state: 'india-karnataka',
  },
  {
    key: 'india-uttar-pradesh-maunath-bhanjan',
    name: 'Maunath Bhanjan',
    state: 'india-uttar-pradesh',
  },
  {
    key: 'india-uttar-pradesh-modinagar',
    name: 'Modinagar',
    state: 'india-uttar-pradesh',
  },
  {
    key: 'india-bihar-motihari',
    name: 'Motihari',
    state: 'india-bihar',
  },
  {
    key: 'india-punjab-muktsar',
    name: 'Muktsar',
    state: 'india-punjab',
  },
  {
    key: 'india-punjab-moga',
    name: 'Moga',
    state: 'india-punjab',
  },
  {
    key: 'india-gujrat-morvi',
    name: 'Morvi',
    state: 'india-gujrat',
  },
  {
    key: 'india-bihar-munger',
    name: 'Munger',
    state: 'india-bihar',
  },
  {
    key: 'india-gujrat-nadiad',
    name: 'Nadiad',
    state: 'india-gujrat',
  },
  {
    key: 'india-assam-nagaon',
    name: 'Nagaon',
    state: 'india-assam',
  },
  {
    key: 'india-west-bengal-nabadwip',
    name: 'Nabadwip',
    state: 'india-west-bengal',
  },
  {
    key: 'india-mp-nagda',
    name: 'Nagda',
    state: 'india-mp',
  },
  {
    key: 'india-west-bengal-naihati',
    name: 'Naihati',
    state: 'india-west-bengal',
  },
  {
    key: 'india-maharashtra-nandurbar',
    name: 'Nandurbar',
    state: 'india-maharashtra',
  },
  {
    key: 'india-maharashtra-pimpri-chinchwad',
    name: 'Pimpri Chinchwad',
    state: 'india-maharashtra',
  },
  {
    key: 'india-maharashtra-navi-mumbai',
    name: 'Navi Mumbai',
    state: 'india-maharashtra',
  },
  {
    key: 'india-tamil-nadu-neyveli',
    name: 'Neyveli',
    state: 'india-tamil-nadu',
  },
  {
    key: 'india-punjab-s.a.s.-nagar',
    name: 'S.A.S. Nagar',
    state: 'india-punjab',
  },
  {
    key: 'india-west-bengal-north-barrackpur',
    name: 'North Barrackpur',
    state: 'india-west-bengal',
  },
  {
    key: 'india-west-bengal-north-dum-dum',
    name: 'North Dum Dum',
    state: 'india-west-bengal',
  },
  {
    key: 'india-uttar-pradesh-orai',
    name: 'Orai',
    state: 'india-uttar-pradesh',
  },
  {
    key: 'india-maharashtra-osmanabad',
    name: 'Osmanabad',
    state: 'india-maharashtra',
  },
  {
    key: 'india-puducherry-ozhukarai',
    name: 'Ozhukarai',
    state: 'india-puducherry',
  },
  {
    key: 'india-tamil-nadu-pallavaram',
    name: 'Pallavaram',
    state: 'india-tamil-nadu',
  },
  {
    key: 'india-gujrat-palanpur',
    name: 'Palanpur',
    state: 'india-gujrat',
  },
  {
    key: 'india-haryana-palwal',
    name: 'Palwal',
    state: 'india-haryana',
  },
  {
    key: 'india-gujrat-patan',
    name: 'Patan',
    state: 'india-gujrat',
  },
  {
    key: 'india-maharashtra-panvel',
    name: 'Panvel',
    state: 'india-maharashtra',
  },
  {
    key: 'india-west-bengal-panihati',
    name: 'Panihati',
    state: 'india-west-bengal',
  },
  {
    key: 'india-mp-pithampur',
    name: 'Pithampur',
    state: 'india-mp',
  },
  {
    key: 'india-tamil-nadu-pudukkottai',
    name: 'Pudukkottai',
    state: 'india-tamil-nadu',
  },
  {
    key: 'india-uttar-pradesh-rae-bareli',
    name: 'Rae Bareli',
    state: 'india-uttar-pradesh',
  },
  {
    key: 'india-bihar-purnia',
    name: 'Purnia',
    state: 'india-bihar',
  },
  {
    key: 'india-west-bengal-raiganj',
    name: 'Raiganj',
    state: 'india-west-bengal',
  },
  {
    key: 'india-west-bengal-rajpur-sonarpur',
    name: 'Rajpur Sonarpur',
    state: 'india-west-bengal',
  },
  {
    key: 'india-chattisgarh-rajnandgaon',
    name: 'Rajnandgaon',
    state: 'india-chattisgarh',
  },
  {
    key: 'india-chattisgarh-raigarh',
    name: 'Raigarh',
    state: 'india-chattisgarh',
  },
  {
    key: 'india-tamil-nadu-rajapalayam',
    name: 'Rajapalayam',
    state: 'india-tamil-nadu',
  },
  {
    key: 'india-west-bengal-raniganj',
    name: 'Raniganj',
    state: 'india-west-bengal',
  },
  {
    key: 'india-mp-rewa',
    name: 'Rewa',
    state: 'india-mp',
  },
  {
    key: 'india-uttar-pradesh-rampur',
    name: 'Rampur',
    state: 'india-uttar-pradesh',
  },
  {
    key: 'india-karnataka-ranibennur',
    name: 'Ranibennur',
    state: 'india-karnataka',
  },
  {
    key: 'india-karnataka-robertson-pet',
    name: 'Robertson Pet',
    state: 'india-karnataka',
  },
  {
    key: 'india-odisha-raurkela-',
    name: 'Raurkela ',
    state: 'india-odisha',
  },
  {
    key: 'india-uttarakhand-rudrapur',
    name: 'Rudrapur',
    state: 'india-uttarakhand',
  },
  {
    key: 'india-bihar-saharsa',
    name: 'Saharsa',
    state: 'india-bihar',
  },
  {
    key: 'india-maharashtra-thane',
    name: 'Thane',
    state: 'india-maharashtra',
  },
  {
    key: 'india-maharashtra-solapur',
    name: 'Solapur',
    state: 'india-maharashtra',
  },
  {
    key: 'india-mp-murwara',
    name: 'Murwara',
    state: 'india-mp',
  },
  {
    key: 'india-west-bengal-rishra',
    name: 'Rishra',
    state: 'india-west-bengal',
  },
  {
    key: 'india-uttarakhand-roorkee',
    name: 'Roorkee',
    state: 'india-uttarakhand',
  },
  {
    key: 'india-odisha-sambalpur',
    name: 'Sambalpur',
    state: 'india-odisha',
  },
  {
    key: 'india-bihar-sasaram',
    name: 'Sasaram',
    state: 'india-bihar',
  },
  {
    key: 'india-mp-sagar',
    name: 'Sagar',
    state: 'india-mp',
  },
  {
    key: 'india-maharashtra-sangli-miraj-kupwad',
    name: 'Sangli Miraj Kupwad',
    state: 'india-maharashtra',
  },
  {
    key: 'india-maharashtra-satara',
    name: 'Satara',
    state: 'india-maharashtra',
  },
  {
    key: 'india-west-bengal-santipur',
    name: 'Santipur',
    state: 'india-west-bengal',
  },
  {
    key: 'india-mp-satna',
    name: 'Satna',
    state: 'india-mp',
  },
  {
    key: 'india-andhra-pradesh-secunderabad',
    name: 'Secunderabad',
    state: 'india-andhra-pradesh',
  },
  {
    key: 'india-mp-sehore',
    name: 'Sehore',
    state: 'india-mp',
  },
  {
    key: 'india-mp-seoni',
    name: 'Seoni',
    state: 'india-mp',
  },
  {
    key: 'india-west-bengal-serampore',
    name: 'Serampore',
    state: 'india-west-bengal',
  },
  {
    key: 'india-uttar-pradesh-shamli',
    name: 'Shamli',
    state: 'india-uttar-pradesh',
  },
  {
    key: 'india-mp-shivpuri',
    name: 'Shivpuri',
    state: 'india-mp',
  },
  {
    key: 'india-uttar-pradesh-shahjahanpur',
    name: 'Shahjahanpur',
    state: 'india-uttar-pradesh',
  },
  {
    key: 'india-west-bengal-siliguri',
    name: 'Siliguri',
    state: 'india-west-bengal',
  },
  {
    key: 'india-uttar-pradesh-sitapur',
    name: 'Sitapur',
    state: 'india-uttar-pradesh',
  },
  {
    key: 'india-west-bengal-south-dum-dum',
    name: 'South Dum Dum',
    state: 'india-west-bengal',
  },
  {
    key: 'india-mp-singrauli',
    name: 'Singrauli',
    state: 'india-mp',
  },
  {
    key: 'india-bihar-siwan',
    name: 'Siwan',
    state: 'india-bihar',
  },
  {
    key: 'india-rajasthan-sujangarh',
    name: 'Sujangarh',
    state: 'india-rajasthan',
  },
  {
    key: 'india-andhra-pradesh-srikakulam',
    name: 'Srikakulam',
    state: 'india-andhra-pradesh',
  },
  {
    key: 'india-uttar-pradesh-sultanpur',
    name: 'Sultanpur',
    state: 'india-uttar-pradesh',
  },
  {
    key: 'india-andhra-pradesh-suryapet',
    name: 'Suryapet',
    state: 'india-andhra-pradesh',
  },
  {
    key: 'india-andhra-pradesh-tadpatri',
    name: 'Tadpatri',
    state: 'india-andhra-pradesh',
  },
  {
    key: 'india-andhra-pradesh-tadepalligudem',
    name: 'Tadepalligudem',
    state: 'india-andhra-pradesh',
  },
  {
    key: 'india-tamil-nadu-thoothukkudi',
    name: 'Thoothukkudi',
    state: 'india-tamil-nadu',
  },
  {
    key: 'india-andhra-pradesh-greater-hyderabad',
    name: 'Greater Hyderabad',
    state: 'india-andhra-pradesh',
  },
  {
    key: 'india-uttarakhand-haldwani-cum-kathgodam',
    name: 'Haldwani-cum-Kathgodam',
    state: 'india-uttarakhand',
  },
  {
    key: 'india-karnataka-hubli-dharwad',
    name: 'Hubli-Dharwad',
    state: 'india-karnataka',
  },
  {
    key: 'india-west-bengal-jalpaiguri',
    name: 'Jalpaiguri',
    state: 'india-west-bengal',
  },
  {
    key: 'india-tamil-nadu-kancheepuram',
    name: 'Kancheepuram',
    state: 'india-tamil-nadu',
  },
  {
    key: 'india-tamil-nadu-karaikkudi',
    name: 'Karaikkudi',
    state: 'india-tamil-nadu',
  },
  {
    key: 'india-west-bengal-kulti',
    name: 'Kulti',
    state: 'india-west-bengal',
  },
  {
    key: 'india-west-bengal-medinipur',
    name: 'Medinipur',
    state: 'india-west-bengal',
  },
  {
    key: 'india-uttar-pradesh-mughalsarai',
    name: 'Mughalsarai',
    state: 'india-uttar-pradesh',
  },
  {
    key: 'india-andhra-pradesh-narasaraopet',
    name: 'Narasaraopet',
    state: 'india-andhra-pradesh',
  },
  {
    key: 'india-mizoram-aizawl',
    name: 'Aizawl',
    state: 'india-mizoram',
  },
  {
    key: 'india-west-bengal-alipurduar',
    name: 'Alipurduar',
    state: 'india-west-bengal',
  },
  {
    key: 'india-uttar-pradesh-greater-noida',
    name: 'Greater Noida',
    state: 'india-uttar-pradesh',
  },
  {
    key: 'india-haryana-ambala',
    name: 'Ambala',
    state: 'india-haryana',
  },
  {
    key: 'india-maharashtra-amaravati',
    name: 'Amaravati',
    state: 'india-maharashtra',
  },
  {
    key: 'india-uttar-pradesh-bakshpur',
    name: 'Bakshpur',
    state: 'india-uttar-pradesh',
  },
  {
    key: 'india-karnataka-bellary',
    name: 'Bellary',
    state: 'india-karnataka',
  },
  {
    key: 'india-rajasthan-bharatpur',
    name: 'Bharatpur',
    state: 'india-rajasthan',
  },
  {
    key: 'india-bihar-bhagalpur',
    name: 'Bhagalpur',
    state: 'india-bihar',
  },
  {
    key: 'india-west-bengal-bhatpara',
    name: 'Bhatpara',
    state: 'india-west-bengal',
  },
  {
    key: 'india-rajasthan-bhilwara',
    name: 'Bhilwara',
    state: 'india-rajasthan',
  },
  {
    key: 'india-karnataka-bidar',
    name: 'Bidar',
    state: 'india-karnataka',
  },
  {
    key: 'india-gujrat-bhuj',
    name: 'Bhuj',
    state: 'india-gujrat',
  },
  {
    key: 'india-chattisgarh-bilaspur',
    name: 'Bilaspur',
    state: 'india-chattisgarh',
  },
  {
    key: 'india-tamil-nadu-coimbatore',
    name: 'Coimbatore',
    state: 'india-tamil-nadu',
  },
  {
    key: 'india-assam-dibrugarh',
    name: 'Dibrugarh',
    state: 'india-assam',
  },
  {
    key: 'india-tamil-nadu-tambaram',
    name: 'Tambaram',
    state: 'india-tamil-nadu',
  },
  {
    key: 'india-telangana-adilabad',
    name: 'Adilabad',
    state: 'india-telangana',
  },
  {
    key: 'india-uttar-pradesh-allahabad',
    name: 'Allahabad',
    state: 'india-uttar-pradesh',
  },
  {
    key: 'india-odisha-cuttack',
    name: 'Cuttack',
    state: 'india-odisha',
  },
  {
    key: 'india-maharashtra-navi-mumbai-panvel-raigarh',
    name: 'Navi Mumbai Panvel Raigarh',
    state: 'india-maharashtra',
  },
  {
    key: 'india-west-bengal-puruliya',
    name: 'Puruliya',
    state: 'india-west-bengal',
  },
  {
    key: 'india-west-bengal-rajarhat-gopalpur',
    name: 'Rajarhat Gopalpur',
    state: 'india-west-bengal',
  },
  {
    key: 'india-haryana-rewari',
    name: 'Rewari',
    state: 'india-haryana',
  },
  {
    key: 'india-uttar-pradesh-sambhal',
    name: 'Sambhal',
    state: 'india-uttar-pradesh',
  },
  {
    key: 'india-rajasthan-sawai-madhopur',
    name: 'Sawai Madhopur',
    state: 'india-rajasthan',
  },
  {
    key: 'india-uttar-pradesh-shikohabad',
    name: 'Shikohabad',
    state: 'india-uttar-pradesh',
  },
  {
    key: 'india-gujrat-surendranagar-dudhrej',
    name: 'Surendranagar Dudhrej',
    state: 'india-gujrat',
  },
  {
    key: 'india-maharashtra-aurangabad',
    name: 'Aurangabad',
    state: 'india-maharashtra',
  },
  {
    key: 'india-jk-baramula',
    name: 'Baramula',
    state: 'india-jk',
  },
  {
    key: 'india-tamil-nadu-cuddalore',
    name: 'Cuddalore',
    state: 'india-tamil-nadu',
  },
  {
    key: 'india-andhra-pradesh-chirala',
    name: 'Chirala',
    state: 'india-andhra-pradesh',
  },
  {
    key: 'india-karnataka-chikka-mandya',
    name: 'Chikka Mandya',
    state: 'india-karnataka',
  },
  {
    key: 'india-haryana-bhiwani',
    name: 'Bhiwani',
    state: 'india-haryana',
  },
  {
    key: 'india-odisha-brahmapur',
    name: 'Brahmapur',
    state: 'india-odisha',
  },
  {
    key: 'india-uttar-pradesh-budaun',
    name: 'Budaun',
    state: 'india-uttar-pradesh',
  },
  {
    key: 'india-uttar-pradesh-bulandshahr',
    name: 'Bulandshahr',
    state: 'india-uttar-pradesh',
  },
  {
    key: 'india-kerala-calicut',
    name: 'Calicut',
    state: 'india-kerala',
  },
  {
    key: 'india-uttar-pradesh-bareilly',
    name: 'Bareilly',
    state: 'india-uttar-pradesh',
  },
  {
    key: 'india-punjab-amritsar',
    name: 'Amritsar',
    state: 'india-punjab',
  },
  {
    key: 'india-uttar-pradesh-fyzabad',
    name: 'Fyzabad',
    state: 'india-uttar-pradesh',
  },
  {
    key: 'india-uttar-pradesh-hapur',
    name: 'Hapur',
    state: 'india-uttar-pradesh',
  },
  {
    key: 'india-karnataka-hospet',
    name: 'Hospet',
    state: 'india-karnataka',
  },
  {
    key: 'india-assam-jorhat',
    name: 'Jorhat',
    state: 'india-assam',
  },
  {
    key: 'india-telangana-khammam',
    name: 'Khammam',
    state: 'india-telangana',
  },
  {
    key: 'india-tamil-nadu-kumbakonam',
    name: 'Kumbakonam',
    state: 'india-tamil-nadu',
  },
  {
    key: 'india-gujrat-navsari',
    name: 'Navsari',
    state: 'india-gujrat',
  },
  {
    key: 'india-telangana-karimnagar',
    name: 'Karimnagar',
    state: 'india-telangana',
  },
  {
    key: 'india-jk-jammu',
    name: 'Jammu',
    state: 'india-jk',
  },
  {
    key: 'india-tamil-nadu-dindigul',
    name: 'Dindigul',
    state: 'india-tamil-nadu',
  },
  {
    key: 'india-arunachal-pradesh-itanagar',
    name: 'Itanagar',
    state: 'india-arunachal-pradesh',
  },
  {
    key: 'india-uttar-pradesh-mirzapur',
    name: 'Mirzapur',
    state: 'india-uttar-pradesh',
  },
  {
    key: 'india-gujrat-rajkot',
    name: 'Rajkot',
    state: 'india-gujrat',
  },
  {
    key: 'india-andhra-pradesh-ongole',
    name: 'Ongole',
    state: 'india-andhra-pradesh',
  },
  {
    key: 'india-andaman-nicobar-port-blair',
    name: 'Port Blair',
    state: 'india-andaman-nicobar',
  },
  {
    key: 'india-andhra-pradesh-rajahmundry',
    name: 'Rajahmundry',
    state: 'india-andhra-pradesh',
  },
  {
    key: 'india-mp-gwalior',
    name: 'Gwalior',
    state: 'india-mp',
  },
  {
    key: 'india-assam-dispur',
    name: 'Dispur',
    state: 'india-assam',
  },
  {
    key: 'india-sikkim-gangtok',
    name: 'Gangtok',
    state: 'india-sikkim',
  },
  {
    key: 'india-west-bengal-haora',
    name: 'Haora',
    state: 'india-west-bengal',
  },
  {
    key: 'india-west-bengal-haldia',
    name: 'Haldia',
    state: 'india-west-bengal',
  },
  {
    key: 'india-andhra-pradesh-kagaznagar',
    name: 'Kagaznagar',
    state: 'india-andhra-pradesh',
  },
  {
    key: 'india-maharashtra-kalyan',
    name: 'Kalyan',
    state: 'india-maharashtra',
  },
  {
    key: 'india-lakshadweep-kavaratti',
    name: 'Kavaratti',
    state: 'india-lakshadweep',
  },
  {
    key: 'india-maharashtra-malegaon-camp',
    name: 'Malegaon Camp',
    state: 'india-maharashtra',
  },
  {
    key: 'india-tamil-nadu-nagercoil',
    name: 'Nagercoil',
    state: 'india-tamil-nadu',
  },
  {
    key: 'india-telangana-nalgonda',
    name: 'Nalgonda',
    state: 'india-telangana',
  },
  {
    key: 'india-andhra-pradesh-nandyal',
    name: 'Nandyal',
    state: 'india-andhra-pradesh',
  },
  {
    key: 'india-telangana-ramagundam',
    name: 'Ramagundam',
    state: 'india-telangana',
  },
  {
    key: 'india-andhra-pradesh-guntur',
    name: 'Guntur',
    state: 'india-andhra-pradesh',
  },
  {
    key: 'india-punjab-haripur',
    name: 'Haripur',
    state: 'india-punjab',
  },
  {
    key: 'india-manipur-imphal',
    name: 'Imphal',
    state: 'india-manipur',
  },
  {
    key: 'india-haryana-karnal',
    name: 'Karnal',
    state: 'india-haryana',
  },
  {
    key: 'india-rajasthan-pali',
    name: 'Pali',
    state: 'india-rajasthan',
  },
  {
    key: 'india-uttar-pradesh-hata',
    name: 'Hata',
    state: 'india-uttar-pradesh',
  },
  {
    key: 'india-karnataka-hubli',
    name: 'Hubli',
    state: 'india-karnataka',
  },
  {
    key: 'india-west-bengal-krishnanagar',
    name: 'Krishnanagar',
    state: 'india-west-bengal',
  },
  {
    key: 'india-telangana-mahabubnagar',
    name: 'Mahabubnagar',
    state: 'india-telangana',
  },
  {
    key: 'india-gujrat-ghandinagar',
    name: 'Ghandinagar',
    state: 'india-gujrat',
  },
  {
    key: 'india-andhra-pradesh-hindupur',
    name: 'Hindupur',
    state: 'india-andhra-pradesh',
  },
  {
    key: 'india-andhra-pradesh-machilipatnam',
    name: 'Machilipatnam',
    state: 'india-andhra-pradesh',
  },
  {
    key: 'india-tamil-nadu-madurai',
    name: 'Madurai',
    state: 'india-tamil-nadu',
  },
  {
    key: 'india-nagaland-kohima',
    name: 'Kohima',
    state: 'india-nagaland',
  },
  {
    key: 'india-haryana-hisar',
    name: 'Hisar',
    state: 'india-haryana',
  },
  {
    key: 'india-tamil-nadu-karur',
    name: 'Karur',
    state: 'india-tamil-nadu',
  },
  {
    key: 'india-haryana-gurugram',
    name: 'Gurugram/ Gurgaon',
    state: 'india-haryana',
  },
  {
    key: 'india-karnataka-gadag-betigeri',
    name: 'Gadag-Betigeri',
    state: 'india-karnataka',
  },
  {
    key: 'india-rajasthan-ganganagar',
    name: 'Ganganagar',
    state: 'india-rajasthan',
  },
  {
    key: 'india-rajasthan-gangapur-city',
    name: 'Gangapur City',
    state: 'india-rajasthan',
  },
  {
    key: 'india-uttar-pradesh-ghazipur',
    name: 'Ghazipur',
    state: 'india-uttar-pradesh',
  },
  {
    key: 'india-uttar-pradesh-fatehpur',
    name: 'Fatehpur',
    state: 'india-uttar-pradesh',
  },
  {
    key: 'india-karnataka-gangawati',
    name: 'Gangawati',
    state: 'india-karnataka',
  },
  {
    key: 'india-uttar-pradesh-gonda',
    name: 'Gonda',
    state: 'india-uttar-pradesh',
  },
  {
    key: 'india-gujrat-godhra',
    name: 'Godhra',
    state: 'india-gujrat',
  },
  {
    key: 'india-andhra-pradesh-guntakal',
    name: 'Guntakal',
    state: 'india-andhra-pradesh',
  },
  {
    key: 'india-west-bengal-kharagpur',
    name: 'Kharagpur',
    state: 'india-west-bengal',
  },
  {
    key: 'india-uttar-pradesh-khurja',
    name: 'Khurja',
    state: 'india-uttar-pradesh',
  },
  {
    key: 'india-punjab-malerkotla',
    name: 'Malerkotla',
    state: 'india-punjab',
  },
  {
    key: 'india-maharashtra-mira-bhayander',
    name: 'Mira Bhayander',
    state: 'india-maharashtra',
  },
  {
    key: 'india-punjab-morena',
    name: 'Morena',
    state: 'india-punjab',
  },
  {
    key: 'india-tamil-nadu-nagapattinam',
    name: 'Nagapattinam',
    state: 'india-tamil-nadu',
  },
  {
    key: 'india-kerala-kozhikode',
    name: 'Kozhikode',
    state: 'india-kerala',
  },
  {
    key: 'india-rajasthan-kishangarh',
    name: 'Kishangarh',
    state: 'india-rajasthan',
  },
  {
    key: 'india-tamil-nadu-madavaram',
    name: 'Madavaram',
    state: 'india-tamil-nadu',
  },
  {
    key: 'india-maharashtra-malegaon',
    name: 'Malegaon',
    state: 'india-maharashtra',
  },
  {
    key: 'india-mp-khargone',
    name: 'Khargone',
    state: 'india-mp',
  },
  {
    key: 'india-uttar-pradesh-khora',
    name: 'Khora',
    state: 'india-uttar-pradesh',
  },
  {
    key: 'india-uttar-pradesh-loni',
    name: 'Loni',
    state: 'india-uttar-pradesh',
  },
  {
    key: 'india-uttar-pradesh-lalitpur',
    name: 'Lalitpur',
    state: 'india-uttar-pradesh',
  },
  {
    key: 'india-andhra-pradesh-madanapalle',
    name: 'Madanapalle',
    state: 'india-andhra-pradesh',
  },
  {
    key: 'india-west-bengal-maheshtala',
    name: 'Maheshtala',
    state: 'india-west-bengal',
  },
  {
    key: 'india-odisha-bhubaneshwar',
    name: 'Bhubaneshwar',
    state: 'india-odisha',
  },
  {
    key: 'india-mp-neemuch',
    name: 'Neemuch',
    state: 'india-mp',
  },
  {
    key: 'india-andhra-pradesh-tenali',
    name: 'Tenali',
    state: 'india-andhra-pradesh',
  },
  {
    key: 'india-haryana-thanesar',
    name: 'Thanesar',
    state: 'india-haryana',
  },
  {
    key: 'india-kerala-thrissur',
    name: 'Thrissur',
    state: 'india-kerala',
  },
  {
    key: 'india-punjab-abohar',
    name: 'Abohar',
    state: 'india-punjab',
  },
  {
    key: 'india-tripura-agartala',
    name: 'Agartala',
    state: 'india-tripura',
  },
  {
    key: 'india-maharashtra-ahmadnagar',
    name: 'Ahmadnagar',
    state: 'india-maharashtra',
  },
  {
    key: 'india-rajasthan-ajmer',
    name: 'Ajmer',
    state: 'india-rajasthan',
  },
  {
    key: 'india-maharashtra-akola',
    name: 'Akola',
    state: 'india-maharashtra',
  },
  {
    key: 'india-kerala-alappuzha',
    name: 'Alappuzha',
    state: 'india-kerala',
  },
  {
    key: 'india-rajasthan-alwar',
    name: 'Alwar',
    state: 'india-rajasthan',
  },
  {
    key: 'india-bihar-aurangabad',
    name: 'Aurangabad',
    state: 'india-bihar',
  },
  {
    key: 'india-uttar-pradesh-bamanpuri',
    name: 'Bamanpuri',
    state: 'india-uttar-pradesh',
  },
  {
    key: 'india-chandigarh-chandigarh',
    name: 'Chandigarh',
    state: 'india-chandigarh',
  },
  {
    key: 'india-chattisgarh-bhilai',
    name: 'Bhilai',
    state: 'india-chattisgarh',
  },
  {
    key: 'india-west-bengal-barddhaman',
    name: 'Barddhaman',
    state: 'india-west-bengal',
  },
  {
    key: 'india-uttar-pradesh-bharauri',
    name: 'Bharauri',
    state: 'india-uttar-pradesh',
  },
  {
    key: 'india-gujrat-bhavnagar',
    name: 'Bhavnagar',
    state: 'india-gujrat',
  },
  {
    key: 'india-uttar-pradesh-pratapgarh',
    name: 'Pratapgarh',
    state: 'india-uttar-pradesh',
  },
  {
    key: 'india-maharashtra-chanda',
    name: 'Chanda',
    state: 'india-maharashtra',
  },
  {
    key: 'india-uttar-pradesh-lakhimpur',
    name: 'Lakhimpur',
    state: 'india-uttar-pradesh',
  },
  {
    key: 'india-gujrat-mahesana',
    name: 'Mahesana',
    state: 'india-gujrat',
  },
  {
    key: 'india-rajasthan-nagaur',
    name: 'Nagaur',
    state: 'india-rajasthan',
  },
  {
    key: 'india-kerala-palakkad',
    name: 'Palakkad',
    state: 'india-kerala',
  },
  {
    key: 'india-maharashtra-nashik',
    name: 'Nashik',
    state: 'india-maharashtra',
  },
  {
    key: 'india-maharashtra-nasik',
    name: 'Nashik',
    state: 'india-maharashtra',
  },
  {
    key: 'india-jharkhand-dhanbad',
    name: 'Dhanbad',
    state: 'india-jharkhand',
  },
  {
    key: 'india-gujrat-ahmedabad',
    name: 'Ahmedabad',
    state: 'india-gujrat',
  },
  {
    key: 'india-uttar-pradesh-noida',
    name: 'Noida',
    state: 'india-uttar-pradesh',
  },
];
