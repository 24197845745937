import brandImage from '../assets/brand-logo-visit-delhi.png';
import './css/header.scss';
function Header() {
  return (
    <div className='header'>
      <div className='headerBrandImageSection'>
        <div className='headerBrandImageSectionImage'>
          <img src={brandImage} alt='Brand Logo' />
        </div>
      </div>
      {/* <nav className='navbar navbar-expand-lg bg-light'>
        <div className='container-fluid'>
           <a className='navbar-brand' href='/'>
            Visit Delhi
          </a>
          <button
            className='navbar-toggler'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#navbarNav'
            aria-controls='navbarNav'
            aria-expanded='false'
            aria-label='Toggle navigation'
          >
            <span className='navbar-toggler-icon'></span>
          </button>
          <div className='collapse navbar-collapse' id='navbarNav'>
            <ul className='navbar-nav'>
              <li className='nav-item'>
                <a className='nav-link active' aria-current='page' href='/'>
                  Home
                </a>
              </li>
              <li className='nav-item'>
                <a className='nav-link' href='/gallery'>
                  Gallery
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav> */}
    </div>
  );
}

export default Header;
