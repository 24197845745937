import React from 'react';
import _ from 'lodash';
import {
  CITIES,
  DEALS_LIST,
  MARKETPLACE_LIST,
  META_PRODUCTS_LIST,
  META_SPECIALITIES_LIST,
  SERVICES_LIST,
} from '../static';
import './css/postLoPopularLinks.scss';
import { fetchTextSearchOptions, isEmptyValue } from '../utils/commonUtils';
import { Link } from 'react-router-dom';
import Text from './common/Text';

const SEARCH_QUERY_PARAMS = {
  CITY: 'city',
  CATEGORY_GROUP: 'category_group',
  CATEGORY: 'category',
  SEARCH_TEXT: 'search_text',
  LOCAL_POST_ONLY: 'local_post_only',
  SERVICE_LOCATION: 'service_location',
  EXPERIENCE: 'experience',
  SPECIALITIES: 'specialities',
  SERVICES: 'services',
};

const POPULAR_SERVICES_SPECIALITIES = [
  'cooking-classes',
  'digital-marketing',
  'fortune-telling-astrology',
  'psychiatry-services',
  'dentistry-services',
  'movers-packers',
  'laundry-service',
  'gardening-landscaping',
  'plumbing-services',
  'specialized-jobs-electrician',
  'hobby-decorative-art',
  'ps-real-estate-agent-management',
  'hc-driving-classes',
  'specialized-jobs-carpenter',
  'music-guitar',
  'da-bharatanatyam',
  'da-kathak',
  'do-gastroenterologist',
  'hr-career-advance-communication-skills',
];

const POPULAR_MARKETPLACE_LINKS_SEARCH = [
  'rent-apartment-flat',
  'buy-sell-apartment-flat',
  'rent-house-villa',
  'buy-sell-house-bungalow-villa',
  'buy-sell-vehicles-car',
  'buy-sell-land-plot',
];
export default function PostLoPopularLinks({ router }) {
  const textSearchOptions = fetchTextSearchOptions(
    SERVICES_LIST,
    META_SPECIALITIES_LIST,
    DEALS_LIST,
    META_PRODUCTS_LIST,
    MARKETPLACE_LIST
  );
  const cityId = 'india-delhi-new-delhi';
  const cityRecords = _.find(CITIES, {
    key: cityId,
  });
  const { CITY, SPECIALITIES } = SEARCH_QUERY_PARAMS;
  const popularServices = POPULAR_SERVICES_SPECIALITIES.map((record) => {
    const serviceRecord = textSearchOptions.find(
      (option) => option.value === record
    );
    let queryRequest = `https://www.postlo.com/search/${CITY}/${cityId}`;
    if (!isEmptyValue(serviceRecord) && serviceRecord?.type === 'services') {
      queryRequest = `${queryRequest}/service/${serviceRecord?.value}`;
    }
    if (!isEmptyValue(serviceRecord) && serviceRecord?.type === 'speciality') {
      queryRequest = `${queryRequest}/service/${serviceRecord?.area}`;
      queryRequest = `${queryRequest}/speciality/${serviceRecord?.value}`;
      queryRequest = `${queryRequest}?${SPECIALITIES}=${serviceRecord?.value}`;
    }
    if (!serviceRecord) {
      return null;
    }
    return (
      <span>
        <a href={queryRequest}>
          <Text color='muted'>{`${serviceRecord?.label} in ${cityRecords?.name}`}</Text>
        </a>
      </span>
    );
  });

  const popularMarketplaceLinks = POPULAR_MARKETPLACE_LINKS_SEARCH.map(
    (record) => {
      const marketplaceRecord = textSearchOptions.find(
        (option) => option.value === record
      );
      if (isEmptyValue(marketplaceRecord)) {
        return null;
      }
      let queryRequest = `https://www.postlo.com/search/${CITY}/${cityId}`;
      if (
        !isEmptyValue(marketplaceRecord) &&
        marketplaceRecord.type === 'marketplace'
      ) {
        queryRequest = `${queryRequest}/buy-sell-rent/${marketplaceRecord.value}`;
      }
      // if (
      //   !isEmptyValue(marketplaceRecord) &&
      //   marketplaceRecord.type === 'product'
      // ) {
      //   queryRequest = `${queryRequest}/buy-sell-rent/${marketplaceRecord.area}`;
      //   queryRequest = `${queryRequest}/product/${marketplaceRecord.value}`;
      //   queryRequest = `${queryRequest}?products=${marketplaceRecord.value}`;
      // }
      const cityDetails = _.find(CITIES, {
        key: cityId,
      });
      return (
        <span>
          <a href={queryRequest}>
            <Text color='muted'>{`${marketplaceRecord?.label} in ${cityDetails?.name}`}</Text>
          </a>
        </span>
      );
    }
  );

  const popularInOtherCities = [];
  const priorityMarketplaceCategory = [
    'rent-apartment-flat',
    'buy-sell-apartment-flat',
    'rent-house-villa',
    'buy-sell-house-bungalow-villa',
    'buy-sell-vehicles-car',
  ];

  const priorityCities = [
    'india-delhi-new-delhi',
    'india-haryana-gurugram',
    'india-uttar-pradesh-noida',
    'india-delhi-delhi',
    'india-mp-bhopal',
  ];
  let priorityCitiesLinks = [];
  for (let cityKey of priorityCities) {
    for (let record of priorityMarketplaceCategory) {
      const servicesOrDealsRecord = textSearchOptions.find(
        (option) => option.value === record
      );
      let queryRequest = `https://www.postlo.com/search/${CITY}/${cityKey}`;
      if (
        !isEmptyValue(servicesOrDealsRecord) &&
        servicesOrDealsRecord.type === 'marketplace'
      ) {
        queryRequest = `${queryRequest}/buy-sell-rent/${servicesOrDealsRecord?.value}`;
      }
      const cityDetails = _.find(CITIES, {
        key: cityKey,
      });
      const link = (
        <span>
          <a href={queryRequest}>
            <Text color='muted'>{`${servicesOrDealsRecord?.label} in ${cityDetails?.name}`}</Text>
          </a>
        </span>
      );
      priorityCitiesLinks.push(link);
    }
  }

  return (
    <div className='popular-search-links'>
      <div className='popular-search-links__category'>
        <div className='popular-search-links__category__heading'>
          <Text>{`Popular services in ${cityRecords?.name}`}</Text>
        </div>
        <div className='popular-search-links__category__links'>
          {popularServices}
        </div>
      </div>
      <div className='popular-search-links__category'>
        <div className='popular-search-links__category__heading'>
          <Text>{`Popular Buy/ Sell/ Rent Links ${cityRecords?.name}`}</Text>
        </div>
        <div className='popular-search-links__category__links'>
          {[
            ...popularMarketplaceLinks,
            ...[
              <span>
                <a href={`https://www.postlo.com/sitemap`}>
                  <Text color='muted'>{`PostLo Sitemap`}</Text>
                </a>
              </span>,
            ],
          ]}
        </div>
      </div>
      {/* <div className='popular-search-links__category'>
        <div className='popular-search-links__category__heading'>
          <Text>Popular in other cities</Text>
        </div>
        <div className='popular-search-links__category__links'>
          {[
            ...popularInOtherCities,
            ...priorityCitiesLinks,
            ...[
              <span>
                <a href={`https://www.postlo.com/sitemap`}>
                  <Text color='muted' >{`PostLo Sitemap`}</Text>
                </a>
              </span>,
            ],
          ]}
        </div>
      </div> */}
    </div>
  );
}
