import Text from './common/Text';
import ReactPlayer from 'react-player';
import './css/articles.scss';
//https://postlo-prod.s3.ap-south-1.amazonaws.com/app/blog/city/delhi/Dilwalon-ki-dilli-combined-1.mp4
function renderArticle() {
  return (
    <>
      <p>
        <h1>
          <Text size='large' weight='bold'>
            Top 10 most popular places of Delhi
          </Text>
        </h1>
      </p>
      <p>
        <Text size='medium'>
          Filled with tourists throughout the year from all parts of the world,
          the super famous Delhi needs no introduction. It has much in store for
          you that won’t stop amusing you right from its rich heritage and
          culture to bustling markets, magnificent monuments, beautiful gardens,
          temples, parks, and museums. Don’t believe us! Go get your bags packed
          and leave right away to be mesmerized by the beauty of the city-Delhi
          which is often said to be “Dilwalon ki Dilli”.
        </Text>
      </p>
      <p>
        <div className={'responsive-image-container'}>
          <div className='player-wrapper'>
            <ReactPlayer
              url={`https://images.postlo.com/app/blog/city/delhi/Dilwalon-ki-dilli-combined-1.mp4`}
              className='react-player'
              width='100%'
              height='100%'
              controls
            />
          </div>
        </div>
      </p>
      <p>
        <Text size='medium'>
          So here goes the list of some places that you must visit in Delhi:
        </Text>
      </p>
      <p>
        <div className={`articlesNumberBullet`}>
          <div className={`articlesNumberBulletNumber`}>
            <Text weight='medium' size='medium'>
              1.
            </Text>
          </div>
          <Text weight='medium' size='medium'>
            RED FORT
          </Text>
        </div>
      </p>
      <p>
        <Text size='medium'>
          Not always red. Yes, you heard it right, the magnificent Red Fort was
          made of white limestone which was later painted in red when the color
          started chipping off. It is the most significant monument that spells
          the story of India’s freedom struggle. Since 1947, every year on 15
          August the Prime Minister has hoisted the tricolor to honor the
          freedom fighters and their sacrifices. The image of the Red Fort on
          the back of the 500 Rupee note symbolizes India’s Independence.
        </Text>
      </p>
      <p>
        <div className={'responsive-image-container'}>
          <img
            src='https://images.postlo.com/app/blog/city/delhi/Red+Fort.jpg'
            layout='fill'
            className={'image'}
          />
        </div>
      </p>
      <p>
        <div className={`articlesNumberBullet`}>
          <div className={`articlesNumberBulletNumber`}>
            <Text weight='medium' size='medium'>
              2.
            </Text>
          </div>
          <Text weight='medium' size='medium'>
            QUTUB MINAR
          </Text>
        </div>
      </p>
      <p>
        <Text size='medium'>
          Delhi is the home of the world’s tallest brick minaret. Yes, it’s Qutb
          Minar which lies at the site of Delhi’s oldest fortified city Lal Kot.
          Built using red sandstone and marble by Qutub-al-Din Aibak it still
          stands tall today and is a popular tourist spot. A picture of the
          minaret is featured on the Delhi Metro Rail Corporation travel cards
          and tokens.{' '}
        </Text>
      </p>
      <p>
        <div className={'responsive-image-container'}>
          <img
            src='https://images.postlo.com/app/blog/city/delhi/Qutub+Minar.jpg'
            layout='fill'
            className={'image'}
          />
        </div>
      </p>
      <p>
        <div className={`articlesNumberBullet`}>
          <div className={`articlesNumberBulletNumber`}>
            <Text weight='medium' size='medium'>
              3.
            </Text>
          </div>
          <Text weight='medium' size='medium'>
            INDIA GATE
          </Text>
        </div>
      </p>
      <p>
        <Text size='medium'>
          India Gate is known as the largest war memorial in India. It is
          dedicated to Indian and British soldiers who sacrificed their lives
          during the First World War and Third Anglo-Afghan War. Just beneath
          the archway stands the Amar Jawan Jyoti which has been kept burning
          since 2019 and has now been shifted to the National War Memorial by
          our honorable Prime Minister-Narendra Modi to pay homage to the fallen
          soldiers who have sacrificed their lives for Independent India.
          Recently, he also unveiled the statue of Netaji Subhash Chandra Bose
          at the India Gate during the inauguration of Kartavya Path which was
          earlier known as Rajpath.{' '}
        </Text>
      </p>
      <p>
        <div className={'responsive-image-container'}>
          <img
            src='https://images.postlo.com/app/blog/city/delhi/India+Gate.jpg'
            layout='fill'
            className={'image'}
          />
        </div>
      </p>
      <p>
        <div className={`articlesNumberBullet`}>
          <div className={`articlesNumberBulletNumber`}>
            <Text weight='medium' size='medium'>
              4.
            </Text>
          </div>
          <Text weight='medium' size='medium'>
            AKSHARDHAM TEMPLE
          </Text>
        </div>
      </p>
      <p>
        <Text size='medium'>
          A sightseeing tour in Delhi is incomplete without the visit to the
          architectural marvel – “Akshardham”. It is a fantastic site with
          unbelievable architecture, a great fountain light and sound show, a
          diorama, and more. Built from carved sandstone and marble, it displays
          the millennia of traditional modern Hindu culture, spirituality, and
          architecture. The temple has won the title of the world’s largest
          Hindu temple in the Guinness Book of World Records. Its complex houses
          lush green open gardens, water bodies, step–well, and bronze statues
          of various Indian heroes, patriots, and warriors. Above all is the
          idol of Lord Swaminarayan which is the prime attraction.
        </Text>
      </p>
      <p>
        <div className={'responsive-image-container'}>
          <img
            src='https://images.postlo.com/app/blog/city/delhi/Akshardham+Temple2.jpg'
            layout='fill'
            className={'image'}
          />
        </div>
      </p>
      <p>
        <div className={`articlesNumberBullet`}>
          <div className={`articlesNumberBulletNumber`}>
            <Text weight='medium' size='medium'>
              5.
            </Text>
          </div>
          <Text weight='medium' size='medium'>
            LOTUS TEMPLE
          </Text>
        </div>
      </p>
      <p>
        <Text size='medium'>
          Don’t forget to add Lotus Temple to your Delhi sightseeing itinerary.
          The experience will be worth remembering as it is the only temple
          which is dedicated to the Baha’i religion in Asia. Its unique
          flowerlike architecture with white petals of lotus neatly arranged
          side by side makes it look stunning even from a distance. The
          all-inclusiveness of the temple goes further than this: there is a
          beautiful garden and a pond within the complex. The temple is open to
          all religions, races, or gender. It has won numerous awards and
          rewards for its mesmerizing architecture.
        </Text>
      </p>
      <p>
        <div className={'responsive-image-container'}>
          <img
            src='https://images.postlo.com/app/blog/city/delhi/Lotus+Temple2.jpg'
            layout='fill'
            className={'image'}
          />
        </div>
      </p>
      <p>
        <div className={`articlesNumberBullet`}>
          <div className={`articlesNumberBulletNumber`}>
            <Text weight='medium' size='medium'>
              6.
            </Text>
          </div>
          <Text weight='medium' size='medium'>
            NATIONAL ZOOLOGICAL PARK
          </Text>
        </div>
      </p>
      <p>
        <Text size='medium'>
          If you have a kid along during your Delhi tour then this place is a
          must-visit. Here you will have a wonderful time watching some special
          places of the Zoo like the Jaguar, Sangai, White tiger, Gaur,
          Lion-tailed Macaque, migratory birds during winters, exotic birds like
          the Great Indian Hornbill, and reptiles like the Indian Sand Boa, and
          Indian Cobra. There are around 200 varieties of trees in the Zoo which
          gives the animals and birds an environment that resembles their
          natural habitat.
        </Text>
      </p>
      <p>
        <div className={'responsive-image-container'}>
          <img
            src='https://images.postlo.com/app/blog/city/delhi/National+Zoological+Park.jpg'
            layout='fill'
            className={'image'}
          />
        </div>
      </p>
      <p>
        <div className={`articlesNumberBullet`}>
          <div className={`articlesNumberBulletNumber`}>
            <Text weight='medium' size='medium'>
              7.
            </Text>
          </div>
          <Text weight='medium' size='medium'>
            CHANDANI CHOWK
          </Text>
        </div>
      </p>
      <p>
        <Text size='medium'>
          Out of all the tourist places to visit in Delhi, Chandani chowk is the
          most popular that you won’t be able to miss. Despite the crowd, and
          narrow lanes, still you should visit here to feel the pulse of the
          city. The busy shopping area is full of spices, dry fruits, jewelry,
          and saris, while the narrow streets are crowded with small shops
          selling handicrafts, traditional Indian sweets, essential oils, and
          more. Like most of the things in Delhi, this place also has historical
          significance as it is one of the oldest markets. Not to miss is the
          grandest street food in the famous Paranthewali Gali.
        </Text>
      </p>
      <p>
        <div className={'responsive-image-container'}>
          <img
            src='https://images.postlo.com/app/blog/city/delhi/Chandani+Chowk.jpg'
            layout='fill'
            className={'image'}
          />
        </div>
      </p>
      <p>
        <div className={`articlesNumberBullet`}>
          <div className={`articlesNumberBulletNumber`}>
            <Text weight='medium' size='medium'>
              8.
            </Text>
          </div>
          <Text weight='medium' size='medium'>
            DILLI HAAT
          </Text>
        </div>
      </p>
      <p>
        <Text size='medium'>
          It resembles a traditional village fair where all that is beautiful,
          artistic, exotic, and tasty is available. Artisans and craftsmen from
          different parts of India showcase their handicrafts, handloom
          products, traditional crafts, metal crafts, and decoration pieces. And
          most of all is the variety of cuisines from all over India in one
          place which is a perfect end to your evening.
        </Text>
      </p>
      <p>
        <div className={'responsive-image-container'}>
          <img
            src='https://images.postlo.com/app/blog/city/delhi/Dilli+Haat.jpg'
            layout='fill'
            className={'image'}
          />
        </div>
      </p>
      <p>
        <div className={`articlesNumberBullet`}>
          <div className={`articlesNumberBulletNumber`}>
            <Text weight='medium' size='medium'>
              9.
            </Text>
          </div>
          <Text weight='medium' size='medium'>
            GURUDWARA BANGLA SAHIB
          </Text>
        </div>
      </p>
      <p>
        <Text size='medium'>
          A popular religious place visited not just by the Sikh community but
          the masses. The Gurudwara complex consists of a lovely Sarovar, a
          school, a library, and a hospital museum. It can be recognized from a
          distance by its golden dome and tall flagpole. The Langar Parshad and
          quotes of Gurbani should not be missed.
        </Text>
      </p>
      <p>
        <div className={'responsive-image-container'}>
          <img
            src='https://images.postlo.com/app/blog/city/delhi/Gurudwara+Bangla+Sahib+3.jpg'
            layout='fill'
            className={'image'}
          />
        </div>
      </p>
      <p>
        <div className={`articlesNumberBullet`}>
          <div className={`articlesNumberBulletNumber`}>
            <Text weight='medium' size='medium'>
              10.
            </Text>
          </div>
          <Text weight='medium' size='medium'>
            CONNAUGHT PLACE
          </Text>
        </div>
      </p>
      <p>
        <Text size='medium'>
          If you love shopping and strolling across the streets then this place
          is a must-visit for you. Swanky stores, globally renowned brands, food
          chains, bars, and restaurants are the perfect places that will bring
          your nightlife to life. A visit here is incomplete without a walk in
          Central Park where the National Flag of India grabs the attention.
        </Text>
      </p>
      <p>
        <div className={'responsive-image-container'}>
          <img
            src='https://images.postlo.com/app/blog/city/delhi/Connaught+Place.jpg'
            layout='fill'
            className={'image'}
          />
        </div>
      </p>
      <p>
        <Text size='medium'>
          Though these are some of the main places of Delhi. But the long list
          continues with many more places like Humayun’s tomb, Lodhi garden,
          Rail Museum, Nizamuddin Dargah, Mughal garden, and Jama masjid that
          are worth visiting.
        </Text>
      </p>
    </>
  );
}

export default function DelhiAttractionsArticle(props) {
  return (
    <div>
      <div>{renderArticle()}</div>
    </div>
  );
}
