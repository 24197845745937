import React from 'react';
import { hasOnlyDigitChar } from '../../utils/commonUtils';
import './css/text.scss';

function getColorClassName(color) {
  switch (color) {
    case 'normal':
      return 'pl-text__color-normal';
    case 'danger':
      return 'pl-text__color-danger';
    case 'muted':
      return 'pl-text__color-muted';
    case 'pattern':
      return 'pl-text__color-pattern';
    case 'primary':
      return 'pl-text__color-primary';
    case 'success':
      return 'pl-text__color-success';
    case 'white':
      return 'pl-text__color-white';
    case 'dark-blue':
      return 'pl-text__color-dark-blue';
    case 'blue':
      return 'pl-text__color-blue';
    case 'brand-orange':
      return 'pl-text__color-brand-orange';
    case 'label':
      return 'pl-text__color-label';
    case 'grey':
      return 'pl-text__color-label';
    case 'warning':
      return 'pl-text__color-yellow';
    default:
      return 'pl-text__color-normal';
  }
}

function getSizeClassName(size) {
  switch (size) {
    case 'extra-small':
      return 'pl-text__size-extra-small';
    case 'small':
      return 'pl-text__size-small';
    case 'regular':
      return 'pl-text__size-regular';
    case 'medium':
      return 'pl-text__size-medium';
    case 'large':
      return 'pl-text__size-large';
    case 'title':
      return 'pl-text__size-title';
    case 'header':
      return 'pl-text__size-header';
    default:
      return 'pl-text__size-regular';
  }
}

function getFontWeightClassName(weight) {
  switch (weight) {
    case 'thin':
      return 'pl-text__weight-thin';
    case 'light':
      return 'pl-text__weight-light';
    case 'normal':
      return 'pl-text__weight-normal';
    case 'medium':
      return 'pl-text__weight-medium';
    case 'bold':
      return 'pl-text__weight-bold';
    case 'black':
      return 'pl-text__weight-black';
    default:
      return 'pl-text__weight-normal';
  }
}

function getWebkitLineclampClassName(lineClamp) {
  switch (lineClamp) {
    case 1:
      return 'pl-text__webkit-lineclamp-1';
    case 2:
      return 'pl-text__webkit-lineclamp-2';
    case 3:
      return 'pl-text__webkit-lineclamp-3';
    default:
      return 'pl-text__webkit-lineclamp-2';
  }
}

const Text = ({
  color = 'normal',
  size = 'regular',
  className = '',
  weight = 'normal',
  lineClamp,
  underline = false,
  children,
  ...attr
}) => {
  let textClassNames = `pl-text ${getColorClassName(
    color
  )} ${getFontWeightClassName(weight)} ${getSizeClassName(size)} ${className}`;

  if (lineClamp && hasOnlyDigitChar(lineClamp)) {
    textClassNames = `${textClassNames} pl-text__lineclamp ${getWebkitLineclampClassName(
      lineClamp
    )}`;
  }
  if (underline) {
    textClassNames = `${textClassNames} pl-text__text-decoration-underline`;
  }
  return (
    <span className={textClassNames} {...attr}>
      {children}
    </span>
  );
};

export default Text;
