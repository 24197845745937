export const MARKETPLACE_LIST = [
  {
    key: 'buy-sell-house-bungalow-villa',
    name: 'House/ Bungalow/ Villa',
    category: 'buy-sell-property',
    name_translations: [
      { language: 'english', value: 'House/ Bungalow/ Villa' },
      { language: 'hindi', value: 'घर/ बंगला/ विला' },
    ],
    searchQuery: 'Buy House/ Bungalow/ Villa',
  },
  {
    key: 'buy-sell-apartment-flat',
    name: 'Apartment/ Flat',
    category: 'buy-sell-property',
    name_translations: [
      { language: 'english', value: 'Apartment/ Flat' },
      { language: 'hindi', value: 'अपार्टमेंट/ फ्लैट' },
    ],
    searchQuery: 'Buy Apartment/ Flat',
  },
  {
    key: 'buy-sell-land-plot',
    name: 'Land/ Plot',
    category: 'buy-sell-property',
    name_translations: [
      { language: 'english', value: 'Land/ Plot' },
      { language: 'hindi', value: 'भूमि/ भूमि का भाग' },
    ],
    searchQuery: 'Buy Land/ Plot',
  },
  {
    key: 'buy-sell-office-shop',
    name: 'Office/ Shop',
    category: 'buy-sell-property',
    name_translations: [
      { language: 'english', value: 'Office/ Shop' },
      { language: 'hindi', value: 'कार्यालय/ दुकान' },
    ],
    searchQuery: 'Buy Office/ Shop',
  },
  {
    key: 'rent-house-villa',
    name: 'House/ Bungalow/ Villa',
    category: 'rent-property',
    name_translations: [
      { language: 'english', value: 'House/ Bungalow/ Villa' },
      { language: 'hindi', value: 'घर/ बंगला/ विला' },
    ],
    searchQuery: 'Rent House/ Bungalow/ Villa',
  },
  {
    key: 'rent-office-shop',
    name: 'Office/ Shop',
    category: 'rent-property',
    name_translations: [
      { language: 'english', value: 'Office/ Shop' },
      { language: 'hindi', value: 'कार्यालय/ दुकान' },
    ],
    searchQuery: 'Rent Office/ Shop',
  },
  {
    key: 'rent-pg-roommate',
    name: 'PG/ Roommate',
    category: 'rent-property',
    name_translations: [
      { language: 'english', value: 'PG/ Roommate' },
      { language: 'hindi', value: 'पीजी / रूममेट' },
    ],
    searchQuery: 'PG/ Roommate',
  },
  {
    key: 'rent-apartment-flat',
    name: 'Apartment/ Flat',
    category: 'rent-property',
    name_translations: [
      { language: 'english', value: 'Apartment/ Flat' },
      { language: 'hindi', value: 'अपार्टमेंट/ फ्लैट' },
    ],
    searchQuery: 'Rent Apartment/ Flat',
  },
  {
    key: 'buy-sell-vehicles-scooter',
    name: 'Scooter',
    category: 'buy-sell-vehicles',
    name_translations: [
      { language: 'english', value: 'Scooter' },
      { language: 'hindi', value: 'स्कूटर' },
    ],
    searchQuery: 'Buy Scooter',
  },
  {
    key: 'buy-sell-vehicles-motorcycle-bike',
    name: 'Motorcycle',
    category: 'buy-sell-vehicles',
    name_translations: [
      { language: 'english', value: 'Motorcycle' },
      { language: 'hindi', value: 'मोटरसाइकिल' },
    ],
    searchQuery: 'Buy Motorcycle',
  },
  {
    key: 'buy-sell-musical-instrument',
    name: 'Musical Instruments',
    category: 'buy-sell-items',
    name_translations: [
      { language: 'english', value: 'Musical Instruments' },
      { language: 'hindi', value: 'संगीत वाद्ययंत्र' },
    ],
    searchQuery: 'Buy/ Sell Musical Instruments (Pre Owned)',
  },
  {
    key: 'buy-sell-computer-mobile-electronics',
    name: 'Computers/ Mobile/ Electronics and accessories',
    category: 'buy-sell-items',
    name_translations: [
      {
        language: 'english',
        value: 'Computers/ Mobile/ Electronics and accessories',
      },
      {
        language: 'hindi',
        value: 'कंप्यूटर / मोबाइल / इलेक्ट्रॉनिक्स और सहायक उपकरण',
      },
    ],
    searchQuery:
      'Buy/ Sell Computers/ Mobile/ Electronics and accessories (Pre Owned)',
  },
  {
    key: 'buy-sell-home-office-furniture',
    name: 'Furniture',
    category: 'buy-sell-items',
    name_translations: [
      { language: 'english', value: 'Furniture' },
      { language: 'hindi', value: 'फ़र्नीचर' },
    ],
    searchQuery: 'Buy/ Sell Furniture (Pre Owned)',
  },
  {
    key: 'buy-sell-appliances',
    name: 'Appliances',
    category: 'buy-sell-items',
    name_translations: [
      { language: 'english', value: 'Appliances' },
      { language: 'hindi', value: 'उपकरण' },
    ],
    searchQuery: 'Buy/ Sell Appliances (Pre Owned)',
  },
  {
    key: 'buy-sell-vehicles-car',
    name: 'Car/ SUV',
    category: 'buy-sell-vehicles',
    name_translations: [
      { language: 'english', value: 'Car/ SUV' },
      { language: 'hindi', value: 'कार/ एसयूवी' },
    ],
    searchQuery: 'Buy Car/ SUV',
  },
];
