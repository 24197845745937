import './css/articles.scss';
import DelhiAttractionsArticle from './DelhiAttractionsArticle';
function Articles() {
  return (
    <div className='articles'>
      <DelhiAttractionsArticle />
    </div>
  );
}

export default Articles;
