export const SERVICES_LIST = [
  {
    key: 'used-car-scooter-motorcycle-dealers',
    name: 'Used Car/ Scooter/ Motorcycle Dealers',
    category: 'other_services',
    name_translations: [
      {
        language: 'english',
        value: 'Used Car/ Scooter/ Motorcycle Dealers',
      },
      {
        language: 'hindi',
        value: 'पुरानी कार/स्कूटर/मोटरसाइकिल के डीलर',
      },
    ],
    searchQuery: 'Used Car Dealer',
  },
  {
    key: 'accounting-tax',
    name: 'Accounting/ Tax services',
    category: 'professional_services',
    name_translations: [
      {
        language: 'english',
        value: 'Accounting/ Tax services',
      },
      {
        language: 'hindi',
        value: 'लेखा / कर सेवाएं',
      },
    ],
    searchQuery: 'Accounting/ Tax services',
  },
  {
    key: 'legal-services',
    name: 'Legal services',
    category: 'professional_services',
    name_translations: [
      {
        language: 'english',
        value: 'Legal services',
      },
      {
        language: 'hindi',
        value: 'कानूनी सेवा',
      },
    ],
    searchQuery: 'Legal services',
  },
  {
    key: 'doctor-services',
    name: 'Doctors',
    category: 'medical_services',
    name_translations: [
      {
        language: 'english',
        value: 'Doctors',
      },
      {
        language: 'hindi',
        value: 'चिकित्सक',
      },
    ],
    searchQuery: 'Doctors',
  },
  {
    key: 'pest-control',
    name: 'Pest Control',
    category: 'specialized_jobs',
    name_translations: [
      {
        language: 'english',
        value: 'Pest Control',
      },
      {
        language: 'hindi',
        value: 'किट - नियत्रण',
      },
    ],
    searchQuery: 'Pest Control',
  },
  {
    key: 'plumbing-services',
    name: 'Plumbing',
    category: 'construction_home_repair',
    name_translations: [
      {
        language: 'english',
        value: 'Plumbing',
      },
      {
        language: 'hindi',
        value: 'पाइपलाइन',
      },
    ],
    searchQuery: 'Plumber',
  },
  {
    key: 'specialized-jobs-carpenter',
    name: 'Carpenter',
    category: 'construction_home_repair',
    name_translations: [
      {
        language: 'english',
        value: 'Carpenter',
      },
      {
        language: 'hindi',
        value: 'बढ़ई',
      },
    ],
    searchQuery: 'Carpenter',
  },
  {
    key: 'specialized-jobs-electrician',
    name: 'Electrician',
    category: 'construction_home_repair',
    name_translations: [
      {
        language: 'english',
        value: 'Electrician',
      },
      {
        language: 'hindi',
        value: 'बिजली मिस्त्री',
      },
    ],
    searchQuery: 'Electrician',
  },
  {
    key: 'painting-white-washing',
    name: 'Painting/ White washing',
    category: 'construction_home_repair',
    name_translations: [
      {
        language: 'english',
        value: 'Painting/ White washing',
      },
      {
        language: 'hindi',
        value: 'पेंटिंग / सफेद धुलाई',
      },
    ],
    searchQuery: 'Painting/ White washing',
  },
  {
    key: 'flooring-roofing-services',
    name: 'Flooring/ Roofing',
    category: 'construction_home_repair',
    name_translations: [
      {
        language: 'english',
        value: 'Flooring/ Roofing',
      },
      {
        language: 'hindi',
        value: 'फर्श / छत',
      },
    ],
    searchQuery: 'Flooring/ Roofing',
  },
  {
    key: 'pathology-diagnostic',
    name: 'Pathology/ Diagnostic centers',
    category: 'medical_services',
    name_translations: [
      {
        language: 'english',
        value: 'Pathology/ Diagnostic centers',
      },
      {
        language: 'hindi',
        value: 'पैथोलॉजी / डायग्नोस्टिक सेंटर',
      },
    ],
    searchQuery: 'Pathology/ Diagnostic centers',
  },
  {
    key: 'appliance-repairs',
    name: 'Machine/ Appliance repairs',
    category: 'specialized_jobs',
    name_translations: [
      {
        language: 'english',
        value: 'Machine/ Appliance repairs',
      },
      {
        language: 'hindi',
        value: 'मशीन / उपकरण मरम्मत',
      },
    ],
    searchQuery: 'Machine/ Appliance repairs',
  },
  {
    key: 'laundry-service',
    name: 'Laundry services',
    category: 'specialized_jobs',
    name_translations: [
      {
        language: 'english',
        value: 'Laundry services',
      },
      {
        language: 'hindi',
        value: 'धुलाई सेवाएं',
      },
    ],
    searchQuery: 'Laundry services',
  },
  {
    key: 'auto-repair',
    name: 'Auto Repair',
    category: 'specialized_jobs',
    name_translations: [
      {
        language: 'english',
        value: 'Auto Repair',
      },
      {
        language: 'hindi',
        value: 'ऑटोमोबाइल की मरम्मत',
      },
    ],
    searchQuery: 'Auto Repair',
  },
  {
    key: 'movers-packers',
    name: 'Movers/ Packers',
    category: 'specialized_jobs',
    name_translations: [
      {
        language: 'english',
        value: 'Movers/ Packers',
      },
      {
        language: 'hindi',
        value: 'मूवर्स / पैकर्स',
      },
    ],
    searchQuery: 'Movers/ Packers',
  },
  {
    key: 'shoe-repair',
    name: 'Shoe Repair',
    category: 'specialized_jobs',
    name_translations: [
      {
        language: 'english',
        value: 'Shoe Repair',
      },
      {
        language: 'hindi',
        value: 'जूता मरम्मत',
      },
    ],
    searchQuery: 'Shoe Repair',
  },
  {
    key: 'cooking-classes',
    name: 'Cooking Classes',
    category: 'hobby_classes',
    name_translations: [
      {
        language: 'english',
        value: 'Cooking Classes',
      },
      {
        language: 'hindi',
        value: 'पाक - कला',
      },
    ],
    searchQuery: 'Cooking Classes',
  },
  {
    key: 'language-classes',
    name: 'Language classes',
    category: 'hobby_classes',
    name_translations: [
      {
        language: 'english',
        value: 'Language classes',
      },
      {
        language: 'hindi',
        value: 'भाषा सीखना',
      },
    ],
    searchQuery: 'Language classes',
  },
  {
    key: 'salons-spas',
    name: 'Salons/ Spas',
    category: 'health_beauty_sports',
    name_translations: [
      {
        language: 'english',
        value: 'Salons/ Spas',
      },
      {
        language: 'hindi',
        value: 'सैलून / स्पा',
      },
    ],
    searchQuery: 'Salons/ Spas',
  },
  {
    key: 'fitness-classes',
    name: 'Workouts/ Yoga',
    category: 'health_beauty_sports',
    name_translations: [
      {
        language: 'english',
        value: 'Workouts/ Yoga',
      },
      {
        language: 'hindi',
        value: 'वर्कआउट / योगा',
      },
    ],
    searchQuery: 'Workouts/ Yoga',
  },
  {
    key: 'school-tuition-classes',
    name: 'School tuition/ Subject classes',
    category: 'academics_career',
    name_translations: [
      {
        language: 'english',
        value: 'School tuition/ Subject classes',
      },
      {
        language: 'hindi',
        value: 'स्कूल ट्यूशन / विषय कक्षाएं',
      },
    ],
    searchQuery: 'School tuition/ Subject classes',
  },
  {
    key: 'hair-skin-care-services',
    name: 'Hair/ Skin care',
    category: 'medical_services',
    name_translations: [
      {
        language: 'english',
        value: 'Hair/ Skin care',
      },
      {
        language: 'hindi',
        value: 'बाल / त्वचा की देखभाल',
      },
    ],
    searchQuery: 'Hair/ Skin care services',
  },
  {
    key: 'dentistry-services',
    name: 'Dentistry',
    category: 'medical_services',
    name_translations: [
      {
        language: 'english',
        value: 'Dentistry',
      },
      {
        language: 'hindi',
        value: 'दंत चिकित्सा',
      },
    ],
    searchQuery: 'Dentist',
  },
  {
    key: 'eye-care-services',
    name: 'Eye care',
    category: 'medical_services',
    name_translations: [
      {
        language: 'english',
        value: 'Eye care',
      },
      {
        language: 'hindi',
        value: 'आंख की देखभाल',
      },
    ],
    searchQuery: 'Ophthalmologist/ optometrist',
  },
  {
    key: 'psychiatry-services',
    name: 'Psychiatry',
    category: 'medical_services',
    name_translations: [
      {
        language: 'english',
        value: 'Psychiatry',
      },
      {
        language: 'hindi',
        value: 'मनश्चिकित्सा',
      },
    ],
    searchQuery: 'Psychiatrist',
  },
  {
    key: 'tailoring-service',
    name: 'Tailoring/ Boutique',
    category: 'specialized_jobs',
    name_translations: [
      {
        language: 'english',
        value: 'Tailoring/ Boutique',
      },
      {
        language: 'hindi',
        value: 'सिलाई / बुटीक',
      },
    ],
    searchQuery: 'Tailoring/ Boutique services',
  },
  {
    key: 'locksmith-service',
    name: 'Locksmith service',
    category: 'specialized_jobs',
    name_translations: [
      {
        language: 'english',
        value: 'Locksmith service',
      },
      {
        language: 'hindi',
        value: 'ताला सेवा',
      },
    ],
    searchQuery: 'Locksmith',
  },
  {
    key: 'physiotherapy-chiropractor',
    name: 'Physiotherapist/ Chiropractor',
    category: 'medical_services',
    name_translations: [
      {
        language: 'english',
        value: 'Physiotherapist/ Chiropractor',
      },
      {
        language: 'hindi',
        value: 'फिजियोथेरेपिस्ट / हाड वैद्य',
      },
    ],
    searchQuery: 'Physiotherapist/ Chiropractor',
  },
  {
    key: 'maid-service',
    name: 'Maid/ Domestic help',
    category: 'domestic_services',
    name_translations: [
      {
        language: 'english',
        value: 'Maid/ Domestic help',
      },
      {
        language: 'hindi',
        value: 'नौकरानी / घरेलू मदद',
      },
    ],
    searchQuery: 'Cleaning service',
  },
  {
    key: 'photography-classes',
    name: 'Photography classes',
    category: 'hobby_classes',
    name_translations: [
      {
        language: 'english',
        value: 'Photography classes',
      },
      {
        language: 'hindi',
        value: 'फोटोग्राफी कक्षाएं',
      },
    ],
    searchQuery: 'Photography classes',
  },
  {
    key: 'beauty-makeup-classes',
    name: 'Beauty/ Makeup Classes',
    category: 'hobby_classes',
    name_translations: [
      {
        language: 'english',
        value: 'Beauty/ Makeup Classes',
      },
      {
        language: 'hindi',
        value: 'सौंदर्य / मेकअप कक्षाएं',
      },
    ],
    searchQuery: 'Beauty training centers',
  },
  {
    key: 'software-mobile-dev',
    name: 'Software/ Mobile App Dev Training',
    category: 'academics_career',
    name_translations: [
      {
        language: 'english',
        value: 'Software/ Mobile App Dev Training',
      },
      {
        language: 'hindi',
        value: 'सॉफ्टवेयर / मोबाइल ऐप',
      },
    ],
    searchQuery: 'Software training centers',
  },
  {
    key: 'hobby-music-singing',
    name: 'Music Vocal/ Instrumental',
    category: 'hobby_classes',
    name_translations: [
      {
        language: 'english',
        value: 'Music Vocal/ Instrumental',
      },
      {
        language: 'hindi',
        value: 'संगीत स्वर / वाद्य',
      },
    ],
    searchQuery: 'Music Vocal/ Instrumental classes',
  },
  {
    key: 'gardening-landscaping',
    name: 'Gardening/ Landscaping',
    category: 'domestic_services',
    name_translations: [
      {
        language: 'english',
        value: 'Gardening/ Landscaping',
      },
      {
        language: 'hindi',
        value: 'बागवानी / भूनिर्माण',
      },
    ],
    searchQuery: 'Gardener/ Landscaper',
  },
  {
    key: 'concrete-jobs',
    name: 'Mason/ Construction labor',
    category: 'construction_home_repair',
    name_translations: [
      {
        language: 'english',
        value: 'Mason/ Construction labor',
      },
      {
        language: 'hindi',
        value: 'मेसन / निर्माण श्रम',
      },
    ],
    searchQuery: 'Mason/ Construction labor',
  },
  {
    key: 'acting-classes-drama-school',
    name: 'Acting Classes/Drama Schools',
    category: 'hobby_classes',
    name_translations: [
      {
        language: 'english',
        value: 'Acting Classes/Drama Schools',
      },
      {
        language: 'hindi',
        value: 'एक्टिंग क्लासेस / ड्रामा स्कूल',
      },
    ],
    searchQuery: 'Acting Classes/Drama Schools',
  },
  {
    key: 'graphic-designing',
    name: 'Graphic Designing',
    category: 'professional_services',
    name_translations: [
      {
        language: 'english',
        value: 'Graphic Designing',
      },
      {
        language: 'hindi',
        value: 'ग्राफिक डिजाइनिंग',
      },
    ],
    searchQuery: 'Graphic Designing',
  },
  {
    key: 'video-audio-services',
    name: 'Video/ Audio Services',
    category: 'professional_services',
    name_translations: [
      {
        language: 'english',
        value: 'Video/ Audio Services',
      },
      {
        language: 'hindi',
        value: 'वीडियो / ऑडियो सेवाएँ',
      },
    ],
    searchQuery: 'Video/ Audio Services',
  },
  {
    key: 'creative-technical-writing',
    name: 'Creative/ Technical Writing',
    category: 'professional_services',
    name_translations: [
      {
        language: 'english',
        value: 'Creative/ Technical Writing',
      },
      {
        language: 'hindi',
        value: 'रचनात्मक / तकनीकी लेखन',
      },
    ],
    searchQuery: 'Creative/ Technical Writing',
  },
  {
    key: 'web-designing',
    name: 'Web Designing',
    category: 'professional_services',
    name_translations: [
      {
        language: 'english',
        value: 'Web Designing',
      },
      {
        language: 'hindi',
        value: 'वेब डिजाइनिंग',
      },
    ],
    searchQuery: 'Web Designing',
  },
  {
    key: 'fencing-services',
    name: 'Fencing',
    category: 'construction_home_repair',
    name_translations: [
      {
        language: 'english',
        value: 'Fencing',
      },
      {
        language: 'hindi',
        value: 'बाड़ लगाना',
      },
    ],
    searchQuery: 'Fencing',
  },
  {
    key: 'other-party-entertainment',
    name: 'Party Entertainment',
    category: 'other_services',
    name_translations: [
      {
        language: 'english',
        value: 'Party Entertainment',
      },
      {
        language: 'hindi',
        value: ' पार्टी मनोरंजन',
      },
    ],
    searchQuery: 'Party Entertainment',
  },
  {
    key: 'digital-marketing',
    name: 'Digital Marketing',
    category: 'professional_services',
    name_translations: [
      {
        language: 'english',
        value: 'Digital Marketing',
      },
      {
        language: 'hindi',
        value: 'डिजिटल विपणन',
      },
    ],
    searchQuery: 'Digital marketing services',
  },
  {
    key: 'cook-catering',
    name: 'Cooking service',
    category: 'domestic_services',
    name_translations: [
      {
        language: 'english',
        value: 'Cooking service',
      },
      {
        language: 'hindi',
        value: 'कुक',
      },
    ],
    searchQuery: 'Cooking service',
  },
  {
    key: 'hr-career-advance',
    name: 'Career development',
    category: 'academics_career',
    name_translations: [
      {
        language: 'english',
        value: 'Career development',
      },
      {
        language: 'hindi',
        value: 'कैरियर विकास',
      },
    ],
    searchQuery: 'Career development centers',
  },
  {
    key: 'other-sports',
    name: 'Other sports',
    category: 'health_beauty_sports',
    name_translations: [
      {
        language: 'english',
        value: 'Other sports',
      },
      {
        language: 'hindi',
        value: 'अन्य खेल',
      },
    ],
    searchQuery: '',
  },
  {
    key: 'ps-real-estate-agent-management',
    name: 'Real estate agent/ management',
    category: 'professional_services',
    name_translations: [
      {
        language: 'english',
        value: 'Real estate agent/ management',
      },
      {
        language: 'hindi',
        value: 'रियल एस्टेट एजेंट / प्रबंधन',
      },
    ],
    searchQuery: 'Real estate agency',
  },
  {
    key: 'event-catering',
    name: 'Events/ Catering',
    category: 'other_services',
    name_translations: [
      {
        language: 'english',
        value: 'Events/ Catering',
      },
      {
        language: 'hindi',
        value: 'इवेंट/ खानपान',
      },
    ],
    searchQuery: 'Events/ Catering',
  },
  {
    key: 'hobby-decorative-art',
    name: 'Drawing/ Painting/ Sculpture',
    category: 'hobby_classes',
    name_translations: [
      {
        language: 'english',
        value: 'Drawing/ Painting/ Sculpture',
      },
      {
        language: 'hindi',
        value: 'ड्राइंग / पेंटिंग / मूर्तिकला',
      },
    ],
    searchQuery: 'Drawing/ Painting/ Sculpture classes',
  },
  {
    key: 'interior-design',
    name: 'Interior design/ decoration',
    category: 'construction_home_repair',
    name_translations: [
      {
        language: 'english',
        value: 'Interior design/ decoration',
      },
      {
        language: 'hindi',
        value: 'आंतरिक डिजाइन / सजावट',
      },
    ],
    searchQuery: 'Interior Design/ Decorations',
  },
  {
    key: 'hobby-dance',
    name: 'Dance classes',
    category: 'hobby_classes',
    name_translations: [
      {
        language: 'english',
        value: 'Dance classes',
      },
      {
        language: 'hindi',
        value: 'नृत्य कक्षाएं',
      },
    ],
    searchQuery: 'Dance classes',
  },
  {
    key: 'travel-service',
    name: 'Travel service',
    category: 'other_services',
    name_translations: [
      {
        language: 'english',
        value: 'Travel service',
      },
      {
        language: 'hindi',
        value: 'यात्रा सेवाएँ',
      },
    ],
    searchQuery: 'Travel services',
  },
  {
    key: 'exam-coachings',
    name: 'Exam coachings',
    category: 'academics_career',
    name_translations: [
      {
        language: 'english',
        value: 'Exam coachings',
      },
      {
        language: 'hindi',
        value: 'परीक्षा कोचिंग',
      },
    ],
    searchQuery: 'Coaching centers',
  },
  {
    key: 'cloud-network-cert',
    name: 'Cloud/ Network Certifications',
    category: 'academics_career',
    name_translations: [
      {
        language: 'english',
        value: 'Cloud/ Network Certifications',
      },
      {
        language: 'hindi',
        value: 'क्लाउड / नेटवर्क प्रमाणपत्र',
      },
    ],
    searchQuery: 'Cloud Training',
  },
  {
    key: 'hobby-classes-film',
    name: 'Filmmaking classes',
    category: 'hobby_classes',
    name_translations: [
      {
        language: 'english',
        value: 'Filmmaking classes',
      },
      {
        language: 'hindi',
        value: 'फिल्म निर्माण कक्षाएं',
      },
    ],
    searchQuery: 'Filmmaking classes',
  },
  {
    key: 'builder-architects',
    name: 'Builders/ Architects',
    category: 'construction_home_repair',
    name_translations: [
      {
        language: 'english',
        value: 'Builders/ Architects',
      },
      {
        language: 'hindi',
        value: 'बिल्डर्स / आर्किटेक्ट्स',
      },
    ],
    searchQuery: 'Construction Builders/ Architects',
  },
  {
    key: 'mobile-computer-repair',
    name: 'Mobile/ Computer/ Electronics repair',
    category: 'specialized_jobs',
    name_translations: [
      {
        language: 'english',
        value: 'Mobile/ Computer/ Electronics repair',
      },
      {
        language: 'hindi',
        value: 'मोबाइल / कंप्यूटर / इलेक्ट्रॉनिक्स की मरम्मत',
      },
    ],
    searchQuery: 'Mobile/ Computer/ Electronics repair',
  },
  {
    key: 'alternative-therapy-medicine',
    name: 'Alternative Therapy/ Medicine',
    category: 'medical_services',
    name_translations: [
      {
        language: 'english',
        value: 'Alternative Therapy/ Medicine',
      },
      {
        language: 'hindi',
        value: 'वैकल्पिक थेरेपी/ दवा',
      },
    ],
    searchQuery: 'Alternative Therapy/ Medicine',
  },
  {
    key: 'veterinary-services',
    name: 'Veterinary Services',
    category: 'medical_services',
    name_translations: [
      {
        language: 'english',
        value: 'Veterinary Services',
      },
      {
        language: 'hindi',
        value: 'पशु चिकित्सा सेवाएं',
      },
    ],
    searchQuery: 'Veterinary Clinic',
  },
  {
    key: 'other-medical-services',
    name: 'Other health care/ medical services\t',
    category: 'medical_services',
    name_translations: [
      {
        language: 'english',
        value: 'Other health care/ medical services',
      },
      {
        language: 'hindi',
        value: 'अन्य स्वास्थ्य देखभाल / चिकित्सा सेवाएं',
      },
    ],
    searchQuery: '',
  },
  {
    key: 'others-ocademics-career-services',
    name: 'Others Academics/ Career services',
    category: 'academics_career',
    name_translations: [
      {
        language: 'english',
        value: 'Others Academics/ Career services',
      },
      {
        language: 'hindi',
        value: 'अन्य शैक्षणिक/ व्यवसाय सेवाएं',
      },
    ],
    searchQuery: '',
  },
  {
    key: 'other-construction-home-repair-services',
    name: 'Other construction/ home repair services',
    category: 'construction_home_repair',
    name_translations: [
      {
        language: 'english',
        value: 'Other construction/ home repair services',
      },
      {
        language: 'hindi',
        value: 'अन्य निर्माण / घर की मरम्मत सेवाएं',
      },
    ],
    searchQuery: '',
  },
  {
    key: 'security-guard-service',
    name: 'Security/ Guard service',
    category: 'specialized_jobs',
    name_translations: [
      {
        language: 'english',
        value: 'Security/ Guard service',
      },
      {
        language: 'hindi',
        value: 'सुरक्षा / गार्ड सेवा',
      },
    ],
    searchQuery: 'Security/ Guard service',
  },
  {
    key: 'other-domestic-services',
    name: 'Other domestic services',
    category: 'domestic_services',
    name_translations: [
      {
        language: 'english',
        value: 'Other domestic services',
      },
      {
        language: 'hindi',
        value: 'अन्य घरेलू सेवाएं',
      },
    ],
    searchQuery: '',
  },
  {
    key: 'other-specialized-jobs',
    name: 'Other specialized jobs',
    category: 'specialized_jobs',
    name_translations: [
      {
        language: 'english',
        value: 'Other specialized jobs',
      },
      {
        language: 'hindi',
        value: 'अन्य विशिष्ट कार्य',
      },
    ],
    searchQuery: '',
  },
  {
    key: 'other-professional-services',
    name: 'Other professional services',
    category: 'professional_services',
    name_translations: [
      {
        language: 'english',
        value: 'Other professional services',
      },
      {
        language: 'hindi',
        value: 'अन्य पेशेवर सेवाएं',
      },
    ],
    searchQuery: '',
  },
  {
    key: 'other-hobby-classes',
    name: 'Other hobby classes',
    category: 'hobby_classes',
    name_translations: [
      {
        language: 'english',
        value: 'Other hobby classes',
      },
      {
        language: 'hindi',
        value: 'अन्य शौक कक्षाएं',
      },
    ],
    searchQuery: '',
  },
  {
    key: 'other-health-beauty-services',
    name: 'Other Health/ Beauty services',
    category: 'health_beauty_sports',
    name_translations: [
      {
        language: 'english',
        value: 'Other Health/ Beauty services',
      },
      {
        language: 'hindi',
        value: 'अन्य स्वास्थ्य / सौंदर्य सेवाएं',
      },
    ],
    searchQuery: '',
  },
  {
    key: 'pet-care',
    name: 'Pet care',
    category: 'domestic_services',
    name_translations: [
      {
        language: 'english',
        value: 'Pet care',
      },
      {
        language: 'hindi',
        value: 'पालतू जानवरों की देखभाल',
      },
    ],
    searchQuery: 'Pet care services',
  },
  {
    key: 'sanitation-services',
    name: 'Sanitation services',
    category: 'domestic_services',
    name_translations: [
      {
        language: 'english',
        value: 'Sanitation services',
      },
      {
        language: 'hindi',
        value: 'स्वच्छता सेवाएँ',
      },
    ],
    searchQuery: 'Sanitation Services',
  },
  {
    key: 'domestic-delivery',
    name: 'Domestic delivery',
    category: 'domestic_services',
    name_translations: [
      {
        language: 'english',
        value: 'Domestic delivery',
      },
      {
        language: 'hindi',
        value: 'घरेलू वितरण',
      },
    ],
    searchQuery: 'Domestic delivery services',
  },
  {
    key: 'ds-elderly-care',
    name: 'Elderly Care',
    category: 'domestic_services',
    name_translations: [
      {
        language: 'english',
        value: 'Elderly Care',
      },
      {
        language: 'hindi',
        value: 'बुजुर्गों की देखभाल',
      },
    ],
    searchQuery: 'Elderly care',
  },
  {
    key: 'ds-child-care',
    name: 'Child Care',
    category: 'domestic_services',
    name_translations: [
      {
        language: 'english',
        value: 'Child Care',
      },
      {
        language: 'hindi',
        value: 'बाल देखभाल',
      },
    ],
    searchQuery: 'Child care',
  },
  {
    key: 'loading-auto-services',
    name: 'Loading Auto services',
    category: 'specialized_jobs',
    name_translations: [
      {
        language: 'english',
        value: 'Loading Auto Services',
      },
      {
        language: 'hindi',
        value: 'लोडिंग ऑटो',
      },
    ],
    searchQuery: 'Loading Auto services',
  },
  {
    key: 'hc-driving-classes',
    name: 'Driving classes',
    category: 'hobby_classes',
    name_translations: [
      {
        language: 'english',
        value: 'Driving classes',
      },
      {
        language: 'hindi',
        value: 'ड्राइविंग कक्षाएं',
      },
    ],
    searchQuery: 'Driving school',
  },
  {
    key: 'ps-insurance-services',
    name: 'Insurance services',
    category: 'professional_services',
    name_translations: [
      {
        language: 'english',
        value: 'Insurance services',
      },
      {
        language: 'hindi',
        value: 'बीमा सेवाएं',
      },
    ],
    searchQuery: 'Insurance agent',
  },
  {
    key: 'fortune-telling-astrology',
    name: 'Fortune Telling/ Astrology',
    category: 'other_services',
    name_translations: [
      {
        language: 'english',
        value: 'Fortune Telling/ Astrology',
      },
      {
        language: 'hindi',
        value: 'फॉर्च्यून टेलिंग/ ज्योतिष',
      },
    ],
    searchQuery: 'Fortune Teller/ Astrologer',
  },
  {
    key: 'ds-driver-taxi',
    name: 'Driver/ Taxi service',
    category: 'domestic_services',
    name_translations: [
      {
        language: 'english',
        value: 'Driver/ Taxi service',
      },
      {
        language: 'hindi',
        value: 'ड्राइवर/ टैक्सी सेवा',
      },
    ],
    searchQuery: 'Driver/ Taxi',
  },
];
