import Articles from './Articles';
import PostLoPopularLinks from './PostLoPopularLinks';
import { Helmet } from 'react-helmet';

function Home() {
  return (
    <div className='home'>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Visit Delhi - Top 10 most popular places of Delhi</title>
        <link rel='canonical' href='http://www.visit-new-delhi.com' />
      </Helmet>
      <Articles />
      <PostLoPopularLinks />
    </div>
  );
}

export default Home;
